import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, formatDate } from '@angular/common';
import { DashboardService } from '../service/dashboard.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import swal, { SweetAlertResult } from 'sweetalert2';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { ShowPremiumTotalComponent } from '../dialogs/show-premium-total/show-premium-total.component';
import { SelectPaymentMethodComponent } from '../dialogs/select-payment-method/select-payment-method.component';
import { CashPaymentComponent } from '../dialogs/cash-payment/cash-payment.component';
import { ChequePaymentComponent } from '../dialogs/cheque-payment/cheque-payment.component';
import { CreateCustomerComponent } from '../dialogs/create-customer/create-customer.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DebitCardPaymentComponent } from '../dialogs/debit-card-payment/debit-card-payment.component';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { format } from 'highcharts';
import { MomoPaymentComponent } from '../dialogs/momo-payment/momo-payment.component';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { uploadExcelDataDialogComponent } from '../dialogs/upload-excel-data/upload-excel-data.component';
import { QuoteSummaryComponent } from '../dialogs/quote-summary/quote-summary.component';
import { QuoteSummaryFleetComponent } from '../dialogs/quote-summary-fleet/quote-summary-fleet.component';
import { FinalizeRetryMomoComponent } from '../dialogs/finalize-retry-momo/finalize-retry-momo.component';

interface branchLOVData {
  user_branch_desc: string;
  user_branch_username: string;
  user_branch_code: string;
}

interface brokerLOVData {
  agent_code: string;
  agent_family_name: string;
  agent_work_mobile: string;
  agent_work_email: string;
  agent_agency: string;
  agent_type: string;
}

interface agentLOVData {
  agent_code: string;
  agent_family_name: string;
  agent_work_mobile: string;
  agent_work_email: string;
  agent_agency: string;
  agent_type: string;
}


interface CustomerProfile {
  CLIENT_CODE: string;
  CLIENT_TYPE?: string;
  CLIENT_FAMILY_NAME: string;
  CLIENT_HOME_MOBILE: string;
  CLIENT_FIRST_NAME: string;
  CLIENT_HOME_EMAIL?: string;
  CLIENT_DATE_OF_BIRTH?: string;
  DISPLAY_NAME?: string;
  DISPLAY_SHORT_NAME?: string;
  isNewCustomer?: boolean;
}
interface CustomerResponse {
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  responseData: any;
  profile: CustomerProfile[];
}
interface Make {
  VEHICLE_MAKE_CODE: string;
  VEHICLE_MAKE_DESC: string;
  DISPLAY_SHORT_NAME: string;
}
interface MakeResponse {
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: any;
  responseData: Make[];
}
interface Model {
  VEHICLE_MODEL_CODE: string;
  VEHICLE_MODEL_MAKE_CODE: string;
  VEHICLE_MODEL_DESC: string;
  VEHICLE_MODEL_SECOND_DESC: string;
  DISPLAY_SHORT_NAME: string;
}
interface ModelResponse {
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: any;
  responseData: Model[];
}
interface coverType {
  value: string;
  viewValue: string;
  hide: boolean;
}

interface scheduleTypeResponse {
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: scheduleType[];
}

interface scheduleType {
  TABLED_CODE: string;
  TABLED_NAME: string;
  TABLED_DESIGNATION: string;
  HIDE: boolean;
}

interface CurrencyResponse {
  responseCode: string;
  responseMessage: string;
  code: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: string;
  responseData: Currency[];
}

interface Color {
  TABLED_NAME: string;
  TABLED_CODE: string;
  TABLED_DESIGNATION: string;
  DISPLAY_SHORT_NAME: string;
}

interface colorResponse {
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: string;
  responseData: Color[]
}

interface duration {
  value: string;
  viewValue: string;
  days: string;
  hide: boolean
}

interface BodyType {
  TABLED_CODE: string;
  TABLED_DESIGNATION: string;
}

interface QuoteResponse {
  responseCode: string;
  responseMessage: string;
  responseData: string;
  responseNumber: string;
}

interface BodyTypeResponse {
  responseCode: string;
  responseData: BodyType[];
}

interface year {
  value: string;
  viewValue: string;
}


interface Currency {
  CURRENCY: string;
  DATE_UPDATED: string;
  CURRENCY_VALUE: number;
}
interface accessList {
  value: string;
  viewValue: string;
}


var i: number;


interface Target {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-fleet-motor-quote',
  templateUrl: './fleet-motor-quote.component.html',
  styleUrls: ['./fleet-motor-quote.component.scss'],
  providers: [DatePipe]
})



export class FleetMotorQuoteComponent implements OnInit {
  audience: Target[] = [
    { value: '5', viewValue: 'Agent' },
    { value: '2', viewValue: 'Broker' },
    { value: '1', viewValue: 'Customer' }
  ];

  selectedValue: string = "";
  module: 'client'


  appuser: any;
  //customer
  searchCustomerCtrl = new FormControl();
  customer_email = new FormControl();
  customer_phone = new FormControl();
  customer_date_of_birth = new FormControl();
  product = new FormControl();
  inception_date = new FormControl();
  expiry_date = new FormControl();

  filteredCustomers: CustomerProfile[];

  //make
  searchMakeCtrl = new FormControl();
  loadedMakes: Make[];
  filteredMakes: Make[];

  //model
  searchModelCtrl = new FormControl();
  loadedModels: Model[] = [];
  filteredModels: Model[] = [];

  //color
  searchColorCtrl = new FormControl();
  filteredColors: Color[];

  //loadings
  isCustomerLoading: boolean = false;
  isMakeLoading: boolean = false;
  isModelLoading: boolean = false;
  isColorLoading: boolean = false;

  //is renewal
  renewal: boolean = false
  new: boolean = false
  disableCurrency = false
  annual: boolean = true

  //payload for submitting quote
  payload: any = {};
  filterClientPayload: any = {};

  coverData: coverType[] = [
    { value: '203', viewValue: 'MOTOR THIRD PARTY ONLY', hide: false },
    { value: '201', viewValue: 'MOTOR COMPREHENSIVE', hide: false },
    { value: '202', viewValue: 'THIRD PARTY FIRE & THEFT', hide: false },
  ];

  dobStartAt = new Date(moment(moment().format('YYYY-MM-DD')).subtract(18, 'years').format('YYYY-MM-DD'))
  //dobStartAt = new Date(moment(moment().format('YYYY-MM-DD')).subtract(1, 'days').format('YYYY-MM-DD'))


  //schedules and risks
  scheduleData: scheduleType[] = [];

  isNewCustomer: true;
  bodyData: BodyType[] = [];

  durationData: duration[] = [
    { value: 'annual', viewValue: 'Annual Term', days: '365', hide: false },
    { value: 'shortrate', viewValue: 'Short Term', days: '1', hide: false },
    //{ value: '03', viewValue: 'Prorata Term', days: '1', hide: false }
  ];

  yearData: year[] = [];

  currencyData: Currency[] = []

  accessData: accessList[] = [
    { value: "0", viewValue: "0" },
    { value: "1", viewValue: "1" },
    { value: "2", viewValue: "2" },
    { value: "3", viewValue: "3" },
    { value: "4", viewValue: "4" },
    { value: "5", viewValue: "5" },
    { value: "6", viewValue: "Above 5" },
  ]

  //selected records from dialogs
  selectedCustomer: CustomerProfile = {
    CLIENT_CODE: "",
    CLIENT_TYPE: "",
    CLIENT_FAMILY_NAME: "",
    CLIENT_HOME_MOBILE: "",
    CLIENT_FIRST_NAME: "",
    CLIENT_HOME_EMAIL: "",
    CLIENT_DATE_OF_BIRTH: "",
    DISPLAY_NAME: "",
    DISPLAY_SHORT_NAME: "",
    isNewCustomer: false
  };
  selectedMake: Make = {
    VEHICLE_MAKE_CODE: "",
    VEHICLE_MAKE_DESC: "",
    DISPLAY_SHORT_NAME: ""
  }
  selectedModel: Model = {
    VEHICLE_MODEL_CODE: "",
    VEHICLE_MODEL_MAKE_CODE: "",
    VEHICLE_MODEL_DESC: "",
    VEHICLE_MODEL_SECOND_DESC: "",
    DISPLAY_SHORT_NAME: ""
  }
  selectedColor: Color = {
    TABLED_NAME: "",
    TABLED_CODE: "",
    TABLED_DESIGNATION: "",
    DISPLAY_SHORT_NAME: ""
  }
  //searchCustomerDialogValue :any;
  //prepare quote json
  confirmedQuote: boolean = false;
  errors = {}
  selectedMethod: string;

  //is processing
  processing = false;
  renewal_checked: any = "";


  quote: any = {
    policy_sequence: null,
    legacy_policy_sequence: null,
    legacy_policy_number: null,
    policy_company: null,
    policy_branch: '',
    policy_agency: '',
    policy_office: '',
    broker_user_code: null,
    policy_lob: "",
    policy_status: null,
    policy_status_reason: null,
    product: "",
    duration_type: "",
    agent_code: null,
    client_code: null,
    module: "",
    channel: "1",
    staff:{
          staff_id:"",
          staff_email:""
          },
    prepared_by: "",
    status_of_client: "new",
    status: "active",
    currency_code: "GHC",
    fleet: null,
    exchange_rate: 1.00,
    quote_days: 365,
    days_label: "Days",
    quote_months: null,
    inception_date: "",
    expiry_date: "",
    total_premium: 0.00,
    total_premium_cv: 0.00,
    customer_first_name: "",
    customer_last_name: "",
    customer_email: "",
    customer_phone: "",
    customer_date_of_birth: "",
    customer_type_code: "01",
    intermediary_first_name: "",
    intermediary_last_name: "",
    intermediary_email: "",
    intermediary_phone: "",
    product_description: null,
    platform: "Webapp",
    quote_objects: [{
      objecth_sequence: null,
      objecth_id: null,
      vehicle_registration: "",
      make: "",
      make_code: null,
      quote_model: "",
      model_code: null,
      chasis_number: "",
      body_type_code: "",
      body_type_description: "",
      seats: null,
      year_of_manufacture: "",
      cubic_capacity: null,
      mileage: null,
      sum_insured: 0.00,
      sum_insured_rate: 0.00,
      sum_insured_rate_cv: 0.00,
      total_premium: 0.00,
      total_premium_cv: 0.00,
      color: "",
      color_code: "",
      risk: "",
      object_days: 365,
      object_months: null,
      inception_date: "",
      expiry_date: "",
      excess_amount: 0,
      excess_type_code: "ENA",
      legacy_excess_type_code: "ENA",
      tppd_limit: 0.00,
      tppd_rate: 0.00,
      legacy_tppd_limit: 0.00,
      additional_pa_amt: 0.00,
      additional_pa_charge: 0.00,
      legacy_additional_pa_amt: 0.00,
      legacy_additional_loading: 0.00,
      legacy_discount_amt: 0.00,
      personal_accident_charge: 0.00,
      additional_loading_amt: 0.00,
      additional_loading_rate: 0.00
    }],
    date_updated: "",
    date_created: ""
  }

  quoteDetails: any = [];


  selectedQuote: any;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  sixthFormGroup: FormGroup;

  isOptional = false;

  formattedAmount;
  amount;

  todayDate = new Date();
  dateTxt: string;
  daysTextFieldInput: any;
  isStaff = false
  disableField = false;
  lblPay = "Pay Now"


  @ViewChild(MatPaginator) paginator: MatPaginator;

  length: number = 0
  pageIndex: number = 0
  pageSize: number = 5
  pageSizeOptions = [5]
  pageChange = false;

  dataSource = new MatTableDataSource();
  displayedColumns = ['no', 'policy_sequence', 'vehicle_registration', 'make_model', 'total_premium', 'inception_date', 'expiry_date', 'link', 'edit', 'print', 'delete']

  previousPram: any = ""
  previousLabel: String = "Back to products"
  pageHeaderLabel: String = "New Motor Insurance Quote - Fleet"
  responseCode: any;
  responseMessage: any;
  errorResponse: any;
  selectedPolicy: any = null;

  previousPremium = 0.00

  paymentChannel: string;
  quotePayloadBeforePayment:any

  sob: any = {
    agent_code:'',
    client_code:'',
    broker_code:'',
    agent_family_name: '',
    agent_work_email: '',
    policy_branch_name:'HEAD OFFICE',
    policy_branch_code:'1001'
  }

  isAgentLOVLoading: boolean = false;
  AgentLOV: agentLOVData[];
  filteredAgentLOV: agentLOVData[];
  sob_agent_code = new FormControl();
  sob_broker_code = new FormControl();
  sob_policy_branch_code = new FormControl();

  filteredBrokerLOV: any[];
  BrokerLOV: any[];
  isBrokerLOVLoading: boolean = false

  filteredBranchLOV: any[];
  BranchLOV: any[];
  isBranchLOVLoading: boolean = false



  constructor(
    private datePipe: DatePipe, 
    private _formBuilder: FormBuilder, 
    private service: DashboardService, 
    private router: Router, 
    private activatedRoute: ActivatedRoute, 
    private currencyPipe: CurrencyPipe, 
    public dialog: MatDialog, 
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<FleetMotorQuoteComponent>,
    public dialogRetryRef: MatDialog,
    ) {
    this.dateTxt = this.datePipe.transform(this.todayDate, 'dd/MM/yyyy');

    this.appuser = this.service.currentUser();
    //console.log("module>>>>" + this.appuser.role)


    if (!this.appuser)
      this.router.navigate(['']);

    this.renewal = false;
    this.selectedQuote = null;


  }

  ngOnInit() {

    this.quote.quote_days = this.durationData[0].days;
    this.daysTextFieldInput = parseInt(this.quote.quote_days)
    this.quote.inception_date = this.service.strDateToDBFormat(this.dateTxt)
    //this.quote.expiry_date = moment(this.quote.inception_date).add(this.quote.quote_days, 'days').format('YYYY-MM-DD');
    this.quote.expiry_date = moment(this.quote.inception_date).subtract((this.quote.duration_type == 'shortrate' ? 1 : 0), 'days').add(parseInt(this.quote.quote_days) >= 365 ? 365 : parseInt(this.quote.quote_days), (this.quote.duration_type == 'shortrate' ? 'months' : 'days')).format('YYYY-MM-DD');
    this.quote.duration_type = this.durationData[0].value;
    this.previousPremium = parseFloat(localStorage.getItem('previousPremium'))



    //get querry params from url
    try{
    this.activatedRoute.queryParams.subscribe(
      params => {
      this.previousPram = params['previous'];
    });

    }catch(e){
      // console.log(e)
    }

    

    console.log("quote-activity>>>>>>" + localStorage.getItem("quote_activity"))

    if (localStorage.getItem("quote_activity") == 'new') {


    } else if (this.previousPram == "quotes" || localStorage.getItem("quote_activity") == 'edit' || localStorage.getItem("quote_activity") == 'edit_add') {
      this.previousLabel = "Back to quotes"
      this.pageHeaderLabel = "Motor Insurance Quote - Details"
      this.displayedColumns = this.displayedColumns.filter(item => item !== 'print')

      //disable fields
      this.searchCustomerCtrl = new FormControl({ value: this.quote.client_code, disabled: true });
      this.new = true
      //this.customer_email = new FormControl({ value: this.quote.customer_email, disabled: true });
      //this.customer_phone = new FormControl({ value: this.quote.customer_phone, disabled: true });
      //this.customer_date_of_birth = new FormControl({ value: this.quote.customer_date_of_birth, disabled: true });
      //this.renewal = true //this is to disable cover type, schedule, currency
      //this.inception_date = new FormControl({ value: this.quote.inception_date, disabled: true });

      this.annual = true


    } else if (this.previousPram == "policies" || localStorage.getItem("quote_activity") == 'policy_info_temp' || localStorage.getItem("quote_activity") == 'policy_info' || localStorage.getItem("quote_activity") == 'info_for_print') {

      if (localStorage.getItem("quote_activity") == 'info_for_print') {
        this.previousLabel = "Back to documents"
      } else {
        this.previousLabel = "Back to policies"
      }

      this.pageHeaderLabel = "Motor Insurance Policy - Details"
      this.displayedColumns = this.displayedColumns.filter(item => item !== 'edit')
      this.displayedColumns = this.displayedColumns.filter(item => item !== 'delete')
      //disable fields
      this.searchCustomerCtrl = new FormControl({ value: this.quote.client_code, disabled: true });
      this.customer_email = new FormControl({ value: this.quote.customer_email, disabled: true });
      this.customer_phone = new FormControl({ value: this.quote.customer_phone, disabled: true });
      this.customer_date_of_birth = new FormControl({ value: this.quote.customer_date_of_birth, disabled: true });
      this.renewal = true //this is to disable cover type, schedule, currency
      this.inception_date = new FormControl({ value: this.quote.inception_date, disabled: true });
      this.annual = true

      this.quote = JSON.parse(localStorage.getItem("motor-quote"))

      //populate fields with quote data
      this.populateQuoteValues()
      this.loadPolicyVehicles()


    } else if (this.previousPram == "renewals" || localStorage.getItem("quote_activity") == 'policy_renewals') {
      this.previousLabel = "Back to policy renewals"
      this.pageHeaderLabel = "Motor Insurance Policy Renewal - Details"
      this.lblPay = "Pay Now"
      this.displayedColumns = this.displayedColumns.filter(item => item !== 'print')

      //disable fields
      this.searchCustomerCtrl = new FormControl({ value: this.quote.client_code, disabled: true });
      this.renewal = true //this is to disable cover type, schedule, currency
      //this.annual = true

      this.quote = JSON.parse(localStorage.getItem("motor-quote"))
      this.quote.status_of_client = "renew"

      console.log("quote_activity===> " + localStorage.getItem("quote_activity"))

      //populate fields with quote data
      //this.populateQuoteValues()
      this.loadPolicyRenewals()


    } else if (localStorage.getItem("quote_activity") == 'policy_renewals_edit_add' || localStorage.getItem("quote_activity") == 'policy_renewals_edit') {
      this.previousLabel = "Back to policy renewals"
      this.pageHeaderLabel = "Motor Insurance Policy Renewal - Details"
      this.lblPay = "Pay Now"
      this.displayedColumns = this.displayedColumns.filter(item => item !== 'print')

      //disable fields
      this.searchCustomerCtrl = new FormControl({ value: this.quote.client_code, disabled: true });
      this.renewal = true //this is to disable cover type, schedule, currency
      //this.annual = true

      this.quote = JSON.parse(localStorage.getItem("motor-quote"))
      this.quote.status_of_client = "renew"


      this.loadQuoteVehicles()

    }

    if (localStorage.getItem("motor-quote") && localStorage.getItem("quote_activity") == 'edit' || localStorage.getItem("quote_activity") == 'edit_add' || localStorage.getItem("quote_activity") == 'new_add') {
      this.quote = JSON.parse(localStorage.getItem("motor-quote"))
      console.log("motor-quote>>>>" + JSON.stringify(this.quote))

      //populate fields with quote data
      this.populateQuoteValues()
      this.loadQuoteVehicles()

    }



    if (history.state.renewal) {
      this.renewal = history.state.renewal
      this.renewal_checked = 'renew'
    }

    //   if (this.selectedQuote)
    //   this.searchCustomerCtrl = new FormControl({ value: this.selectedQuote.CLIENT_CODE.toString(), disabled: true });
    //   else if (this.appuser && this.appuser.role == 'client') {
    //   this.quote.customer_code = this.appuser.code;
    //   this.searchCustomerCtrl = new FormControl({ value: this.quote.customer_code, disabled: true });
    // }


    if (this.appuser.role == 'agent' || this.appuser.role == 'broker-user') {
      this.quote.agent_code = this.appuser.code
      this.quote.intermediary_first_name = this.appuser.first_name === null ? this.appuser.last_name : this.appuser.first_name
      this.quote.intermediary_last_name = this.appuser.last_name === null ? this.appuser.first_name : this.appuser.last_name
      this.quote.intermediary_phone = this.appuser.phone
      this.quote.intermediary_email = this.appuser.email
      this.quote.prepared_by = this.appuser.first_name + " " + this.appuser.last_name


    } else if (this.appuser.role == 'client') {
      this.selectedCustomer = {
        CLIENT_CODE: this.appuser.client_id,
        CLIENT_FIRST_NAME: this.appuser.first_name,
        CLIENT_FAMILY_NAME: this.appuser.last_name,
        CLIENT_HOME_EMAIL: this.appuser.email,
        DISPLAY_SHORT_NAME: `${this.appuser.first_name} ${this.appuser.last_name}`,
        CLIENT_HOME_MOBILE: this.appuser.phone
      };

      console.log("appuser ---> " + JSON.stringify(this.appuser))

      this.quote.client_code = this.appuser.code
      this.searchCustomerCtrl = new FormControl({ value: this.quote.client_code, disabled: true });
      this.quote.customer_first_name = this.appuser.first_name
      this.quote.customer_last_name = this.appuser.last_name
      this.quote.customer_email = this.appuser.email
      this.quote.customer_phone = this.appuser.phone
      this.quote.customer_date_of_birth = this.service.strDateToDBFormat(this.appuser.dob)



    }else if (this.appuser.role == 'staff'){

        this.quote = {
          ...this.quote,
          module: 'staff',
          staff: {
            staff_id: this.appuser.code,
            staff_email: this.appuser.email
          }
        }

        this.onLoadAgentLOV()
        this.onFilterAgentLOV()
        this.onLoadBrokerLOV()
        this.onFilterBrokerLOV()
        this.onLoadBranchLOV()
    
       


    }else{}



    if (this.appuser.code == '30741') {
      this.isStaff = true
    }





    this.selectedMethod = 'Mobile';

    // this.onDaysChanged(parseInt(this.quote.quote_days))
    this.showCurrencies();
    this.onFilterCustomer();


    try{
      this.activatedRoute.paramMap.subscribe(
        params => {
            this.paymentChannel = params.get('payment-channel');
            //this.paymentStatus = params.get('payment-status');
        }
  
      );

    }catch(e){
     // console.log(e)
    }


    if(this.paymentChannel=='ecobankpay'){
      this.quotePayloadBeforePayment = JSON.parse(localStorage.getItem('quotePayload'))
      console.log("quotePayloadBeforePayment====>" + this.quotePayloadBeforePayment.policy_sequence)

      this.transactionStatus()

    }




  }


  disableFields() { }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



  //input change
  cubic_capacity_change(event) {
    for (let i = 0; i < this.scheduleData.length; i++)

      this.scheduleData[i].HIDE = false;
    if (!isNaN(+`${event.target.value}`) && parseFloat(event.target.value) > 3000) {
      for (let i = 0; i < this.scheduleData.length; i++) {
        if (this.scheduleData[i].TABLED_DESIGNATION == 'OWN GOODS CARRYING Z.300 (UPTO - 3000 CC)')
          this.scheduleData[i].HIDE = true;
      }
    }
    else if (!isNaN(+`${event.target.value}`) && parseFloat(event.target.value) <= 3000) {
      for (let i = 0; i < this.scheduleData.length; i++) {
        if (this.scheduleData[i].TABLED_DESIGNATION == 'OWN GOODS CARRYING Z.300 (ABOVE - 3000 CC)')
          this.scheduleData[i].HIDE = true;
      }
    }
  }



  populateQuoteValues() {
    console.log('channel>>>>>>> ' + this.quote.channel.toString())

    this.quote.inception_date = this.service.strDateToDBFormat(this.quote.inception_date)
    this.quote.expiry_date = this.service.strDateToDBFormat(this.quote.expiry_date)
    this.daysTextFieldInput = this.quote.quote_days.toString()
    this.quote.customer_date_of_birth = this.service.strDateToDBFormat(this.quote.customer_date_of_birth)
    this.quote.product = this.quote.product.toString()
    this.quote.duration_type = this.quote.duration_type.toString()
    this.quote.channel = this.quote.channel.toString()
    this.sob.agent_family_name = this.quote.intermediary_last_name
    this.sob.agent_work_email = this.quote.intermediary_email
    this.sob_agent_code.setValue(this.quote.agent_code)
    this.sob_broker_code.setValue(this.quote.agent_code )

    
    if (this.quote.product === '203') {
      this.quote.check_excess_buy = this.quote.can_excess_buy = false
      this.quote.check_ncd = this.quote.can_ncd = false
      this.quote.check_fd = this.quote.can_fd = false
      this.quote.check_vehicle_age_loading = this.quote.can_vehicle_age_loading = false
      this.quote.check_cc_loading = this.quote.can_cc_loading = false
      this.quote.sum_insured = "0"

      this.quote.exchange_rate = "1"
      this.quote.currency_code = "GHC"
      this.disableCurrency = true

    } else {
      this.quote.sum_insured = ""

      this.disableCurrency = false
    }
    
  
  }

  // getVehiclesFromQuoteList() {
  //   this.dataSource.data = this.quote.quote_objects
  //   this.pageSize = 5
  //   this.length = this.quote.quote_objects.length
  // }


  loadQuoteVehicles = () => {
    this.payload = {
      module: "agent",
      page_size: "5",
      current_page: "0",
      policy_sequence: this.quote.policy_sequence
    }

    if (this.appuser.role === 'client') {
      this.payload = {
        ...this.payload,
        module: 'client',
        client_code: this.appuser.code
      }
    } else if (this.appuser.role === 'agent') {
      this.payload = {
        ...this.payload,
        module: 'agent',
        agent_code: this.appuser.code
      }
    } else if (this.appuser.role === 'broker-user') {
      this.payload = {
        ...this.payload,
        module: 'broker-user',
        broker_user_code: this.appuser.code
      }
    } else if(this.appuser.role==='staff'){
      this.payload={
        ...this.payload,
        module:'staff',
        staff: {
          staff_id: this.appuser.code,
          staff_email: this.appuser.email
        }
      }
    }
    else { }



    this.service.swalAlart.showLoading();
    this.service.getquote(this.payload)
      .toPromise()
      .then((data: any) => {
        this.quoteDetails = data.pageData.content
        const selectedQuote = this.quoteDetails.find((r: any) => r.policy_sequence === this.quote.policy_sequence);

        this.dataSource.data = selectedQuote.quote_objects
        this.pageSize = 5
        this.length = selectedQuote.quote_objects.length

        this.quote.total_premium = this.quoteDetails[0].total_premium
        this.quote.total_premium_cv = this.quoteDetails[0].total_premium_cv
        this.quote.product_description = this.quoteDetails[0].product_description.product_description

        this.quote = data.pageData.content[0]
        console.log("motor-quote>>>>>" + JSON.stringify(this.quote))

        localStorage.setItem('motor-quote', JSON.stringify(this.quote))

        console.log("customer_date_of_birth >>>>>" + this.quote.customer_date_of_birth)

        this.populateQuoteValues()
        //this.onDaysChanged(parseInt(this.quote.quote_days))




        //this.quote.quote_objects = selectedQuote.quote_objects


        this.service.swalAlart.close();
      }).catch(error => {
        this.service.swalAlart.close();
      })

  }

  loadPolicyRenewals = () => {
    this.payload = {
      module: "client",
      page_size: "5",
      current_page: "0",
      client_code: this.quote.client_code,
      policy_sequence: this.quote.policy_sequence
    }

    if (this.appuser.role === 'agent') {
      this.payload = {
        ...this.payload,
        agent_code: this.appuser.code
      }
    } else if (this.appuser.role === 'broker-user') {
      this.payload = {
        ...this.payload,
        broker_user_code: this.appuser.code
      }
    } else if(this.appuser.role==='staff'){
      this.payload={
        ...this.payload,
        module:'staff',
        staff: {
          staff_id: this.appuser.code,
          staff_email: this.appuser.email
        }
      }
    }
    




    this.service.swalAlart.showLoading();
    this.service.getPoliciesForRenewal(this.payload)
      .toPromise()
      .then((data: any) => {
        this.quoteDetails = data.pageData.content
        const selectedQuote = this.quoteDetails.find((r: any) => r.policy_sequence === this.quote.policy_sequence);

        this.dataSource.data = selectedQuote.quote_objects
        this.pageSize = 5
        this.length = selectedQuote.quote_objects.length

        this.quote.total_premium = this.quoteDetails[0].total_premium
        this.quote.total_premium_cv = this.quoteDetails[0].total_premium_cv
        this.quote.product_description = this.quoteDetails[0].product_description.product_description

        this.quote = data.pageData.content[0]
        console.log("motor-quote>>>>>" + JSON.stringify(this.quote))

        localStorage.setItem('motor-quote', JSON.stringify(this.quote))
        localStorage.setItem('previousPremium', this.quote.total_premium)

        this.previousPremium = parseFloat(localStorage.getItem('previousPremium'))


        console.log("quote-duration_type>>>>>>" + this.quote.duration_type)
        if (this.quote.duration_type == 'shortrate') {
          this.annual = false
        } else {
          this.annual = true
        }


        this.onPolicyExpiry()





        this.service.swalAlart.close();
      }).catch(error => {
        this.service.swalAlart.close();
      })

  }

  onPolicyExpiry() {
    // this.quote.quote_days = 365
    // this.daysTextFieldInput = this.quote.quote_days.toString()
    this.quote.customer_date_of_birth = this.service.strDateToDBFormat(this.quote.customer_date_of_birth)
    this.quote.product = this.quote.product.toString()
    this.quote.duration_type = this.quote.duration_type.toString()

    let todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    let expiredDate = this.datePipe.transform(this.service.strDateToDBFormat(this.quote.expiry_date), 'yyyy-MM-dd');
    console.log('todayDate>>>>>>', todayDate)
    console.log('expiredDate>>>>>', expiredDate)

    console.log("quote_days>>>>>>" + this.quote.quote_days.toString())
    console.log("duration_type>>>>>>" + this.quote.duration_type.toString())

    if (this.quote.duration_type == "shortrate") {
      this.daysTextFieldInput = this.quote.quote_days.toString()
      let days = parseInt(this.quote.quote_days);

      console.log("inception_date>>>>>>" + this.quote.inception_date)
      //this.quote.inception_date = this.datePipe.transform(this.service.strDateToDBFormat(this.quote.inception_date), 'yyyy-MM-dd');
      this.quote.inception_date = todayDate

      let start_date = this.quote.inception_date ? moment(this.quote.inception_date).format('YYYY-MM-DD') : null;
      let end_date = moment(start_date).add(!isNaN(+`${days}`) ? days : 1, 'days').format('YYYY-MM-DD');
      console.log("end_date>>>>>>" + end_date)

      this.quote.expiry_date = end_date


    } else {
      this.quote.quote_days = 365
      this.daysTextFieldInput = this.quote.quote_days.toString()

      if (todayDate >= expiredDate) {
        console.log('---policy expired----');
        this.quote.inception_date = todayDate
        this.quote.expiry_date = moment(this.quote.inception_date).subtract((this.quote.duration_type == 'shortrate' ? 1 : 0), 'days').add(parseInt(this.quote.quote_days) >= 365 ? 365 : parseInt(this.quote.quote_days), (this.quote.duration_type == 'shortrate' ? 'months' : 'days')).format('YYYY-MM-DD');

      } else {
        console.log('---policy not expired yet-----');
        this.quote.inception_date = todayDate
        this.quote.expiry_date = moment(this.quote.inception_date).subtract((this.quote.duration_type == 'shortrate' ? 1 : 0), 'days').add(parseInt(this.quote.quote_days) >= 365 ? 365 : parseInt(this.quote.quote_days), (this.quote.duration_type == 'shortrate' ? 'months' : 'days')).format('YYYY-MM-DD');

        // this.quote.inception_date = moment(this.service.strDateToDBFormat(this.quote.expiry_date)).add(1, 'days').format('YYYY-MM-DD')
        // this.quote.expiry_date = moment(this.quote.inception_date).subtract(1, 'days').add(1, 'years').format('YYYY-MM-DD');

      }

    }




    this.initRenewal()
  }

  initRenewal = () => {
    this.processing = true;
    let prepared_by = `${this.appuser.first_name ? this.appuser.first_name : ''} ${this.appuser.last_name ? this.appuser.last_name : ''}`.trim();

    this.quote.prepared_by = prepared_by
    this.quote.policy_branch = this.appuser.agency
    this.quote.fleet = true
    this.quote.module = this.appuser.role.toLowerCase(),
      //this.quote.status_of_client = (this.renewal_checked == 'renew' ? 'renew' : 'new'),

      console.log("post inception_date>>> " + this.quote.inception_date)
    console.log("post expiry date>>>>> " + this.quote.expiry_date)

    this.quote.customer_date_of_birth = moment(this.quote.customer_date_of_birth).format('DD/MM/YYYY'),
      this.quote.inception_date = moment(this.quote.inception_date).format('DD/MM/YYYY'),
      this.quote.expiry_date = moment(this.quote.expiry_date).format('DD/MM/YYYY'),


      // this.quote.quote_objects[this.objecth_position].inception_date = this.quote.inception_date
      // this.quote.quote_objects[this.objecth_position].expiry_date = this.quote.expiry_date
      this.quote.client_code = this.quote.client_code,
      this.quote.policy_lob = "20",
      this.quote.platform = "Webapp"
    //this.quote.quote_objects[this.objecth_position].object_days = this.quote.quote_days

    this.quote.date_created = ""
    this.quote.updated = ""
    this.quote.status_of_client = "renew"

    if (this.appuser.role == 'agent') {
      this.quote = {
        ...this.quote,
        agent_code: this.appuser.code.toString(),
        client_code: this.quote.client_code.toString(),
        broker_user_code: '',
      }
    }
    else if (this.appuser.role == 'client') {
      this.quote = {
        ...this.quote,
        agent_code: '',
        client_code: this.appuser.code.toString(),
        broker_user_code: '',
      }
    } else if (this.appuser.role == 'broker-user') {
      this.quote = {
        ...this.quote,
        agent_code: this.appuser.broker_user_main_broker_code.toString(),
        client_code: this.quote.client_code.toString(),
        broker_user_code: this.appuser.code.toString(),
      }
    }else if(this.appuser.role == 'staff'){
      this.quote = {
        ...this.quote,
        staff:{
          staff_id: this.appuser.code,
          staff_email: this.appuser.email
          },
        policy_branch: this.sob.policy_branch_code,
        staff_user_code: this.appuser.code,
      }
     
    }

    //connect to api
    this.createNewRenewalQuote(this.quote)

  }

  createNewRenewalQuote(postPayload: any) {
    postPayload.quote_activity = "First"

    this.service.swalAlart.showLoading()
    this.service.createQuote(postPayload)
      .toPromise()
      .then((response: any) => {
        this.service.swalAlart.close()

        this.responseCode = response.responseCode
        this.responseMessage = response.responseMessage
        this.errorResponse = response.errorResponse

        if (response.responseCode == '000') {
          console.log("policy_sequence>>>>>" + response.responseObject)
          this.quote.policy_sequence = response.responseObject.policy_sequence

          localStorage.setItem('motor-quote', JSON.stringify(response.responseObject))
          if (localStorage.getItem("quote_activity") == 'new' || localStorage.getItem("quote_activity") == 'new_add') {
            localStorage.setItem('quote_activity', 'edit_add')

          } else if (localStorage.getItem("quote_activity") == 'policy_renewals') {
            localStorage.setItem('quote_activity', 'policy_renewals_edit_add')
          }


          swal.fire({
            icon: 'success',
            text: 'New renewal premium computation successful',
            timer: 15000,
            showConfirmButton: true
          });

          //this.router.navigate(["/fleet-motor-quote"])

          this.loadQuoteVehicles()


        } else if (response.responseCode == 'E60') {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: "Sorry, you cannot renew policy belonging to another agent. Kindly contact your branch office.",
            icon: 'warning',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then(result => {
            if (result) {
              this.populateQuoteValues()
              this.router.navigate(["/renewpolicy"])
            }
          })

        } else if (response.responseCode == 'E57') {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: "Sorry, you cannot renew policy belonging to a different branch. Kindly contact your branch office.",
            icon: 'warning',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then(result => {
            if (result) {
              this.populateQuoteValues()
              this.router.navigate(["/renewpolicy"])
            }
          })

        } else if (response.responseCode == 'E56') {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: "Sorry, we couldn't find any branch associated with this account. Kindly contact your branch office.",
            icon: 'warning',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then(result => {
            if (result) {
              this.populateQuoteValues()
              this.router.navigate(["/renewpolicy"])
            }
          })

        } else if (response.responseCode == 'E54') {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: "Sorry, this policy is not due for renewal.",
            icon: 'warning',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then(result => {
            if (result) {
              this.populateQuoteValues()
              this.router.navigate(["/renewpolicy"])
            }
          })



        } else if (response.responseCode == '400') {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: this.errorResponse,
            icon: 'warning',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then(result => {
            if (result) {
              this.populateQuoteValues()
              this.router.navigate(["/renewpolicy"])
            }
          })
        }

        else {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: this.responseMessage,
            icon: 'error',
          });
          this.populateQuoteValues()
          this.router.navigate(["/renewpolicy"])
        }

      }).catch(error => {
        console.log('Error', error)

        this.processing = false;
        this.service.swalAlart.close();
        this.service.swalAlart.fire({
          title: error.error.responseMessage,
          text: error.error.errorResponse,
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(result => {
          if (result) {
            this.populateQuoteValues()
            this.router.navigate(["/renewpolicy"])
          }
        })

      })
  }

  quoteLevelEdit(postPayload: any) {
    postPayload.quote_activity = "QuoteLevelEdit"

    this.service.swalAlart.showLoading()
    this.service.editVehicle(postPayload)
      .toPromise()
      .then((response: any) => {
        this.service.swalAlart.close()

        this.responseCode = response.responseCode
        this.responseMessage = response.responseMessage

        if (response.responseCode == '000') {
          this.loadQuoteVehicles()


          const dialogRef = this.dialog.open(SelectPaymentMethodComponent, {
            disableClose: true,
            width: '350px',
            data: { quotePayload: this.quote, method: null, total_premium: this.quote.total_premium_cv }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result.hasOwnProperty('data')) {
              this.confirmedQuote = result.data.confirmedQuote;
              this.selectedMethod = result.data.method;

              if (this.selectedMethod)
                this.confirmPin();

              this.validateForm();
            }
          });

        } else if (response.responseCode == '400') {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: this.responseMessage,
            icon: 'error',
          });
          this.populateQuoteValues()
        }

        else {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: this.responseMessage,
            icon: 'error',
          });
          this.populateQuoteValues()
        }

      }).catch(error => {
        //console.log('Error',error)
        this.processing = false;
        this.service.swalAlart.close();
        this.service.swalAlart.fire({
          title: 'Server Error',
          text: this.responseMessage,
          icon: 'error',
        });
        this.populateQuoteValues()
      })
  }

  quoteLevelEditChangedDuration(postPayload: any) {
    postPayload.quote_activity = "QuoteLevelEdit"

    this.service.swalAlart.showLoading()
    this.service.editVehicle(postPayload)
      .toPromise()
      .then((response: any) => {
        this.service.swalAlart.close()

        this.responseCode = response.responseCode
        this.responseMessage = response.responseMessage
        this.errorResponse = response.errorResponse

        if (response.responseCode == '000') {
          this.loadQuoteVehicles()

        } else if (response.responseCode == '400') {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: this.responseMessage,
            icon: 'error',
          });
          this.populateQuoteValues()

        } else if (response.responseCode == '500') {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: this.errorResponse,
            icon: 'error',
          });
          this.populateQuoteValues()
        }

        else {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: this.responseMessage,
            icon: 'error',
          });
          this.populateQuoteValues()
        }

      }).catch(error => {
        //console.log('Error',error)
        this.processing = false;
        this.service.swalAlart.close();
        this.service.swalAlart.fire({
          title: error.error.responseMessage,
          text: error.error.errorResponse,
          icon: 'error',
        });
        this.populateQuoteValues()
      })
  }



  loadPolicyVehicles = () => {
   

    this.payload = {
      module: "agent",
      page_size: "5",
      current_page: "0",
      policy_sequence: this.quote.policy_sequence
    }

    if (this.appuser.role === 'client') {
      this.payload = {
        ...this.payload,
        module: 'client',
        client_code: this.appuser.code
      }
    } else if (this.appuser.role === 'agent') {
      this.payload = {
        ...this.payload,
        module: 'agent',
        agent_code: this.appuser.code
      }
    } else if (this.appuser.role === 'broker-user') {
      this.payload = {
        ...this.payload,
        module: 'broker-user',
        broker_user_code: this.appuser.code
      }

    }else if(this.appuser.role==='broker-admin'){
      console.log("loadPolicyVehicles ===> " + this.appuser.code)
        this.payload={
          ...this.payload,
          module:'agent',
          agent_code:this.appuser.code
        }
  
        this.displayedColumns = ['no', 'policy_sequence', 'vehicle_registration', 'make_model', 'total_premium', 'inception_date', 'expiry_date', 'link', 'print']

      
      } else if(this.appuser.role==='staff'){
        this.payload={
          ...this.payload,
          module:'staff',
          staff: {
            staff_id: this.appuser.code,
            staff_email: this.appuser.email
          }
        }
      }
      


    let policyFilter = "today"
    if (localStorage.getItem("quote_activity") == 'policy_info_temp') {
      policyFilter = "today"

    } else {
      policyFilter = "all"

    }



    if (localStorage.getItem("quote_activity") == 'info_for_print') {

      let client_code = localStorage.getItem("selectedCustomerCode")
      let payload: any = {
        page_size: "5",
        current_page: "0",
        client_code: client_code,
        policy_sequence: this.quote.policy_sequence
      }

      this.service.swalAlart.showLoading();
      this.service.getPoliciesForDocuments(payload)
        .toPromise()
        .then((data: any) => {
          this.quoteDetails = data.pageData.content
          const selectedQuote = this.quoteDetails.find((r: any) => r.policy_sequence === this.quote.policy_sequence);

          this.dataSource.data = selectedQuote.quote_objects
          this.pageSize = 5
          this.length = selectedQuote.quote_objects.length

          this.quote.total_premium = this.quoteDetails[0].total_premium
          this.quote.total_premium_cv = this.quoteDetails[0].total_premium_cv
          this.quote.product_description = this.quoteDetails[0].product_description.product_description

          this.quote = data.pageData.content[0]
          console.log("motor-quote>>>>>" + JSON.stringify(this.quote))

          localStorage.setItem('motor-quote', JSON.stringify(this.quote))

          this.populateQuoteValues()

          this.service.swalAlart.close();
        }).catch(error => {
          this.service.swalAlart.close();
        })


    } else {

      this.service.swalAlart.showLoading();
      this.service.getPolicyInfo(this.payload, policyFilter)
        .toPromise()
        .then((data: any) => {
          this.quoteDetails = data.pageData.content
          const selectedQuote = this.quoteDetails.find((r: any) => r.policy_sequence === this.quote.policy_sequence);

          this.dataSource.data = selectedQuote.quote_objects
          this.pageSize = 5
          this.length = selectedQuote.quote_objects.length

          this.quote.total_premium = this.quoteDetails[0].total_premium
          this.quote.total_premium_cv = this.quoteDetails[0].total_premium_cv
          this.quote.product_description = this.quoteDetails[0].product_description.product_description

          this.quote = data.pageData.content[0]
          console.log("motor-quote>>>>>" + JSON.stringify(this.quote))

          localStorage.setItem('motor-quote', JSON.stringify(this.quote))

          this.populateQuoteValues()

          this.service.swalAlart.close();
        }).catch(error => {
          this.service.swalAlart.close();
        })

    }






  }


  onCoverSelected(code: string) {
    console.log("coverType=======" + code);
    this.quote.product = code;

    //toggle applicable accesses
    this.quote.can_cc_loading = this.quote.can_vehicle_age_loading = this.quote.can_fd = this.quote.can_ncd = this.quote.can_pa = this.quote.can_tppd_limit = this.quote.can_excess_buy = true;
    this.quote.check_cc_loading = this.quote.check_vehicle_age_loading = this.quote.check_fd = this.quote.check_ncd = this.quote.check_pa = this.quote.check_tppd_limit = this.quote.check_excess_buy = false;


    if (this.quote.product === '203') {
      this.quote.check_excess_buy = this.quote.can_excess_buy = false
      this.quote.check_ncd = this.quote.can_ncd = false
      this.quote.check_fd = this.quote.can_fd = false
      this.quote.check_vehicle_age_loading = this.quote.can_vehicle_age_loading = false
      this.quote.check_cc_loading = this.quote.can_cc_loading = false
      this.quote.sum_insured = "0"

      this.quote.exchange_rate = "1"
      this.quote.currency_code = "GHC"
      this.disableCurrency = true

    } else {
      this.quote.sum_insured = ""

      this.disableCurrency = false
    }




    //hide prorata for thirdparty
    for (let i = 0; i < this.durationData.length; i++) {
      if (this.quote.product == '203' && this.durationData[i].value == '03')
        this.durationData[i].hide = true;
      else
        this.durationData[i].hide = false;
    }


    if (!this.quote.edit_mode) {
      // this.validateForm();
    }

  }

  onScheduleSelected(code: string) {
    //console.log("scheduleType=======" + code);
    this.quote.schedule_code = code;

    // this.validateForm();
  }

  //on term selection
  onDurationSelected() {
    console.log("onDurationSelected>>>>true")

    //Get the associated days to the term
    let durations = this.durationData.filter((duration: duration) => duration.value == this.quote.duration_type);

    if (durations.length > 0)
      this.quote.quote_days = durations[0].days;

    //recalculate the end date
    if (!isNaN(+`${this.quote.quote_days}`) && this.quote.inception_date)
      this.quote.expiry_date = moment(this.quote.inception_date).subtract((this.quote.duration_type == 'shortrate' ? 1 : 0), 'days').add(parseInt(this.quote.quote_days) >= 364 ? 364 : parseInt(this.quote.quote_days), (this.quote.duration_type == 'shortrate' ? 'months' : 'days')).format('YYYY-MM-DD');


    //change the days label to months when the term changes to short term
    if (this.quote.duration_type == 'shortrate') {

      this.quote.days_label = 'Days';
      let days = this.quote.quote_days = Math.abs(moment(moment(this.quote.inception_date).format('YYYY-MM-DD')).diff(moment(this.quote.expiry_date).format('YYYY-MM-DD'), 'days'));
      this.quote._days = days ? days : 1;
      this.quote._months = '';

      this.daysTextFieldInput = parseInt(this.quote.quote_days) + 2;
      this.annual = false;

      this.quote.quote_days = this.daysTextFieldInput


    } else if (this.quote.duration_type == 'annual' && this.quote.quote_days != '' && parseInt(this.quote.quote_days) >= 364) {

      this.quote.quote_days = 365;
      this.daysTextFieldInput = parseInt(this.quote.quote_days);
      this.annual = true;


      //increase expiry date by 1 day and reduce by 1
      this.quote.expiry_date = moment(this.quote.inception_date).add(this.quote.quote_days, 'days').format('YYYY-MM-DD');



    } else {
      this.quote.days_label = 'Days';
      let days = this.quote.quote_days = Math.abs(moment(moment(this.quote.inception_date).format('YYYY-MM-DD')).diff(moment(this.quote.expiry_date).format('YYYY-MM-DD'), 'days'));
      this.quote._days = days ? days : 1;
      this.quote._months = '';

    }




    //hide thirdparty for prorata
    for (let i = 0; i < this.coverData.length; i++) {
      if (this.quote.duration_type == 'duration_type' && this.coverData[i].value == '203')
        this.coverData[i].hide = true;
      else
        this.coverData[i].hide = false;
    }



    //save quote level details for payment
    this.processing = true;
    let prepared_by = `${this.appuser.first_name ? this.appuser.first_name : ''} ${this.appuser.last_name ? this.appuser.last_name : ''}`.trim();

    this.quote.prepared_by = prepared_by
    this.quote.policy_branch = this.appuser.agency
    this.quote.fleet = true
    this.quote.module = this.appuser.role.toLowerCase(),
      //this.quote.status_of_client = (this.renewal_checked == 'renew' ? 'renew' : 'new'),

    console.log("post inception_date>>> " + this.quote.inception_date)
    console.log("post customer_date_of_birth>>>>> " + this.quote.customer_date_of_birth)

    if (this.quote.policy_sequence) {
      this.quote.customer_date_of_birth = moment(this.quote.customer_date_of_birth).format('DD/MM/YYYY')
      this.quote.inception_date = moment(this.quote.inception_date).format('DD/MM/YYYY')
      this.quote.expiry_date = moment(this.quote.expiry_date).format('DD/MM/YYYY')
    } else {
      this.quote.customer_date_of_birth = moment(this.quote.customer_date_of_birth).format('YYYY-MM-DD')
      this.quote.inception_date = moment(this.quote.inception_date).format('YYYY-MM-DD')
      this.quote.expiry_date = moment(this.quote.expiry_date).format('YYYY-MM-DD')

    }


    this.quote.client_code = this.quote.client_code
    this.quote.policy_lob = "20"
    this.quote.platform = "Webapp"

    this.quote.date_created = ""
    this.quote.updated = ""

    delete this.quote.quote_objects


    if (this.appuser.role == 'agent') {
      this.quote = {
        ...this.quote,
        agent_code: this.appuser.code.toString(),
        client_code: this.quote.client_code.toString(),
        broker_user_code: '',
      }
    }
    else if (this.appuser.role == 'client') {
      this.quote = {
        ...this.quote,
        agent_code: '',
        client_code: this.appuser.code.toString(),
        broker_user_code: '',
      }
    } else if (this.appuser.role == 'broker-user') {
      this.quote = {
        ...this.quote,
        agent_code: this.appuser.broker_user_main_broker_code.toString(),
        client_code: this.quote.client_code.toString(),
        broker_user_code: this.appuser.code.toString(),
      }
    }

    // //increase expiry date by 1 day and reduce by 1
    // this.quote.expiry_date =  moment(this.quote.inception_date).add(this.quote.quote_days + 1, 'days').format('YYYY-MM-DD');



    //connect to api
    if (this.quote.policy_sequence) {
      this.quoteLevelEditChangedDuration(this.quote)
    }



  }


  onBodySelected(code: string) {
    // console.log("body=======" + code);
    this.quote.body_type_code = code;
    this.validateForm();
  }

  onYearSelected(code: string) {
    //console.log("year=======" + code);
    this.quote.year_of_manufacture = code;
    this.validateForm();
  }

  onCurrencySelected(code: string) {
    let selectedCurrency = this.currencyData.filter((currency: Currency) => currency.CURRENCY == code);

    if (selectedCurrency.length) {
      this.quote.exchange_rate = selectedCurrency[0].CURRENCY_VALUE.toString();
      this.quote.currency_code = code;
    }

    this.validateForm();
  }
  onNCDSelected(code: string) {
    this.quote.ncd_value = code;
  }


  onStartDateChanged() {
    console.log("onStartDateChanged")

    let inception_date = this.quote.inception_date ? moment(this.quote.inception_date).format('YYYY-MM-DD') : null;

    let days = parseInt(this.quote.quote_days);

    if (days >= 365)
      days = 364;

    let expiry_date = moment(inception_date).add(!isNaN(+`${days}`) ? days : 1, 'days').format('YYYY-MM-DD');
    this.quote.expiry_date = expiry_date;

    if (this.quote.inception_date && this.quote.expiry_date) {
      if (this.quote.duration_type == 'annual') {
        this.quote.quote_days = '365';
        this.daysTextFieldInput = '365';

        //increase expiry date by 1 day and reduce by 1
        this.quote.expiry_date = moment(this.quote.inception_date).add(this.quote.quote_days, 'days').format('YYYY-MM-DD');

      } else {
        this.quote.quote_days = this.quote._days = moment(expiry_date).diff(inception_date, 'days').toString();
        this.daysTextFieldInput = parseInt(this.quote.quote_days) + 2;
        this.quote.quote_days = this.daysTextFieldInput


        //  if(this.quote.term=='02')
        //  this.quote.days=this.quote._months=Math.round(moment(expiry_date).diff(inception_date,'days')/31);

      }
    }



    // //increase expiry date by 1 day
    // this.quote.expiry_date =  moment(inception_date).add(days + 1, 'days').format('YYYY-MM-DD');


    this.validateForm();

  }


  onEndDateChanged(date: Date) {
    let inception_date = this.quote.inception_date ? moment(this.quote.inception_date).format('YYYY-MM-DD') : null;

    let days = 0, months = 0;
    if (inception_date) {
      if (this.quote.duration_type == 'shortrate') {
        days = moment(moment(date).format('YYYY-MM-DD')).diff(inception_date, 'days');
        this.quote.quote_days = days.toString();

        this.daysTextFieldInput = parseInt(this.quote.quote_days) + 2;
        this.quote.quote_days = this.daysTextFieldInput


        // months=Math.round(moment(moment(date).format('YYYY-MM-DD')).add(1,'days').diff(inception_date,'days')/31);
        // this.quote.days=this.quote._months=this.quote._months=months;

        // if(this.quote.term=='02' && months==0){
        //  this.quote.term='03';
        //  this.quote.days_label="Days"
        // }
      }
      if (this.quote.duration_type != 'shortrate') {
        days = moment(moment(date).format('YYYY-MM-DD')).diff(inception_date, 'days');
        this.quote.quote_days = days.toString();

        this.daysTextFieldInput = parseInt(this.quote.quote_days);
        this.quote.quote_days = this.daysTextFieldInput

      }
    }

    if (days && days < 365 && this.quote.duration_type == 'annual') {
      months = moment(moment(date).format('YYYY-MM-DD')).diff(inception_date, 'months');

      if (months) {
        this.quote.days_label = "Days";
        this.quote.duration_type = 'shortrate';


        //this.quote.days_label="Months";
        //this.quote.term='02';
        //this.quote.days=months;

      }
      else {
        this.quote.days_label = "Days";
        this.quote.duration_type = 'prorata';
      }
    }
    else if (days == 365) {
      this.quote.duration_type = 'annual';
    }



    this.validateForm();




  }



  //Start Date dateFilters
  startDateFilter = (date: Date) => {
    return moment(moment(date).format('YYYY-MM-DD')).diff(moment().format('YYYY-MM-DD'), 'days') >= 0;
  }
  //End Date dateFilters
  endDateFilter = (date: Date) => {
    let diff = moment(moment(date).format('YYYY-MM-DD')).diff(moment(this.quote.inception_date).format('YYYY-MM-DD'), 'days');
    return diff > 0 && diff <= 364;
  }


  /* handle access changes===*/
  onExcessBuyBackChange(checked: boolean) {
    this.quote.excess_buy_back = checked;
  }
  onTppdChange(checked: boolean) {
    if (!checked)
      this.quote.tppd_value = 0;
    this.quote.tppd = checked;

  }
  onPaChange(checked: boolean) {
    if (!checked)
      this.quote.pa_value = 0;
    this.quote.pa = checked;

  }
  onNcdChange(checked: boolean) {
    if (!checked)
      this.quote.ncd_value = 0;
    this.quote.ncd = checked;

  }
  onFdChange(checked: boolean) {
    this.quote.fd = checked;
  }
  onVehicleAgeLoadingChange(checked: boolean) {
    this.quote.vehicle_age_loading = checked;
  }
  onCCLoadingChange(checked: boolean) {
    this.quote.cc_loading = checked;
  }
  onDiscountChange(checked: boolean) {
    if (!checked)
      this.quote.inputted_discount = 0;

    this.quote.additional_discount = checked;
  }
  onLoadingDiscountChange(checked: boolean) {
    if (!checked)
      this.quote.inputted_loading = 0;

    this.quote.loading_discount = checked;
  }


  onRenewalChange() {

    if (this.renewal_checked == 'renew')
      this.service.swalAlart.fire({
        text: "Premium computations for this policy will be treated as renewal.\nDo you wish to proceed?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: `Yes,Proceed`,
        reverseButtons: true
      })
        .then((dialogResponse) => {
          if (dialogResponse.hasOwnProperty('value') && dialogResponse.value == true) {
            this.renewal = true
            this.quote.edit_mode = true;
            this.quote.product = this.selectedQuote.PRODUCT_CODE
            this.quote.schedule_code = this.selectedQuote.RISK_USAGES
            this.quote.duration_type = this.selectedQuote.DURATION_TYPE == 'annual' ? 'annual' : (this.selectedQuote.DURATION_TYPE == 'shortrate' ? 'shortrate' : 'prorata')
            this.quote.quote_days = this.selectedQuote.QUOTE_DAYS
          }
        })
    else
      this.service.swalAlart.fire({
        text: "Premium computations for this policy will be treated as a new business.\nDo you wish to proceed?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: `Yes,Proceed`,
        reverseButtons: true
      })
        .then((dialogResponse) => {
          if (dialogResponse.hasOwnProperty('value') && dialogResponse.value == true) {
            this.quote.edit_mode = false;
            this.renewal = false;
          }
        })
  }

  //open search customer dialog
  /* openSearchCustomerDialog(){
    const dialogRef = this.dialog.open(SearchMotoQuoteCustomerComponent, {
      width: '600px',
      data: { selectedCustomer: this.selectedCustomer,isNewCustomer: this.isNewCustomer }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.hasOwnProperty('data')){
        this.selectedCustomer = result.data;

        if(this.selectedCustomer.isNewCustomer)
           this.openCustomerCreationDialog();
        else{
          if(this.selectedCustomer){

            this.quote.customer_code=this.selectedCustomer.CLIENT_CODE;
            //this.payload.customer_code=this.quote.customer_code;

            this.quote.customer_first_name=this.selectedCustomer.CLIENT_FIRST_NAME;
            this.quote.customer_last_name=this.selectedCustomer.CLIENT_FAMILY_NAME;
            this.quote.customer_email=this.selectedCustomer.CLIENT_HOME_EMAIL;
            this.quote.customer_phone=this.selectedCustomer.CLIENT_HOME_MOBILE;
            this.quote.customer_phone=this.selectedCustomer.CLIENT_HOME_MOBILE;

            this.quote.date_of_birth=this.selectedCustomer.CLIENT_DATE_OF_BIRTH;
            this.validateForm();
          }
       }

      }

    });
  }
 */



  choosePaymentMethod() {

    //save quote level details for payment
    this.processing = true;

    if (this.appuser.role == 'staff'){
      let prepared_by = `${this.appuser.first_name ? this.appuser.first_name : ''}`.trim();
      this.quote.prepared_by = prepared_by
      this.quote.module = this.appuser.role.toLowerCase()
      //this.quote.policy_branch = 1001

    }else  if (this.appuser.role == 'client'){
      let prepared_by = `${this.appuser.first_name ? this.appuser.first_name : ''} ${this.appuser.last_name ? this.appuser.last_name : ''}`.trim();
      this.quote.prepared_by = prepared_by
      this.quote.policy_branch = 1001
      this.quote.module = this.appuser.role.toLowerCase()
      this.quote.staff = null
    
    }else{
      let prepared_by = `${this.appuser.first_name ? this.appuser.first_name : ''} ${this.appuser.last_name ? this.appuser.last_name : ''}`.trim();
      this.quote.prepared_by = prepared_by
      this.quote.policy_branch = this.appuser.agency
      this.quote.module = this.appuser.role.toLowerCase()
      this.quote.staff = null

    }

   
    this.quote.customer_date_of_birth = moment(this.quote.customer_date_of_birth).format('DD/MM/YYYY'),
    this.quote.inception_date = moment(this.quote.inception_date).format('DD/MM/YYYY'),
    this.quote.expiry_date = moment(this.quote.expiry_date).format('DD/MM/YYYY'),


    this.quote.client_code = this.quote.client_code,
    this.quote.policy_lob = "20",
    this.quote.platform = "Webapp"

    this.quote.date_created = ""
    this.quote.updated = ""

    delete this.quote.quote_objects


    if (this.appuser.role == 'agent') {
      this.quote = {
        ...this.quote,
        agent_code: this.appuser.code.toString(),
        client_code: this.quote.client_code.toString(),
        broker_user_code: '',
      }
    }
    else if (this.appuser.role == 'client') {
      this.quote = {
        ...this.quote,
        agent_code: '',
        client_code: this.appuser.code.toString(),
        broker_user_code: '',
      }
    } else if (this.appuser.role == 'broker-user') {
      this.quote = {
        ...this.quote,
        agent_code: this.appuser.broker_user_main_broker_code.toString(),
        client_code: this.quote.client_code.toString(),
        broker_user_code: this.appuser.code.toString(),
      }

    
    }
    //   else if(this.appuser.role == 'staff'){
    //   this.quote = {
    //     ...this.quote,
    //     policy_branch: this.sob.policy_branch_code
    //   }
     
    // }
  

    //connect to api
    this.quoteLevelEdit(this.quote)

  }

  //confirm login pin to continue
  confirmPin() {
    let currentUser = this.service.currentUser();
    
    if(currentUser.role == "staff"){

      if (this.selectedMethod == 'Mobile')
        this.openMomoPaymentDialog();
      else if (this.selectedMethod == 'Cash')
        this.openCashPaymentDialog();
      else if (this.selectedMethod == 'Cheque')
        this.openChequePaymentDialog();
      else if (this.selectedMethod == 'Card')
        this.openCardPaymentDialog();


    }else if (currentUser.role == "broker-user") {
      this.loginAsBrokerUser()
    } else {
      this.loginAsAgentClient()
    }
  }



  loginAsAgentClient() {
    this.service.swalAlart.fire({
      //title: "Identity Confirmation",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      inputPlaceholder: "Confirm your Pin",
      input: "password",
      reverseButtons: true
    })
      .then((result: SweetAlertResult) => {
        if (result.value) {
          let currentUser = this.service.currentUser();
          //prepare payload
          let loginPayload = {};
          if (currentUser.role == "client") {
            loginPayload = {
              phone: currentUser.phone,
              pin: result.value,
              module: 'client'
            }

          } else if (currentUser.role == "agent") {
            loginPayload = {
              agent_code: currentUser.code,
              pin: result.value,
              module: 'agent'
            }

          } else { }

          swal.showLoading()
          this.service.webLogin(loginPayload)
            .toPromise()
            .then((responseData: any) => {
              swal.hideLoading()
              if (responseData.hasOwnProperty('responseCode') && responseData.responseCode === '000') {
                if (this.selectedMethod == 'Mobile')
                  this.openMomoPaymentDialog();
                else if (this.selectedMethod == 'Cash')
                  this.openCashPaymentDialog();
                else if (this.selectedMethod == 'Cheque')
                  this.openChequePaymentDialog();
                else if (this.selectedMethod == 'Card')
                  this.openCardPaymentDialog();
              }
              else {
                swal.fire({
                  title: 'Wrong credentials!',
                  text: 'Please check and try again',
                  icon: 'error',
                });
                setTimeout(() => { this.confirmPin() }, 2000);
              }

            }).catch(error => {
              swal.fire({
                title: 'Connection Failed!',
                text: 'Login Failed',
                icon: 'error',
              });
              setTimeout(() => { this.confirmPin() }, 2000);
            })
        }
        else
          this.alertSnackBar('No Pin was entered', 'User Not Verified');
      });

  }


  loginAsBrokerUser() {
    this.service.swalAlart.fire({
      //title: "Identity Confirmation",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      inputPlaceholder: "Confirm your Pin",
      input: "password",
      reverseButtons: true
    })
      .then((result: SweetAlertResult) => {
        if (result.value) {
          let currentUser = this.service.currentUser();
          //prepare payload
          let loginPayload = {};
          loginPayload = {
            broker_user_code: currentUser.code,
            broker_user_pin: result.value,
            module: 'broker-user'
          }

          swal.showLoading()
          this.service.webLoginAsBrokerUser(loginPayload)
            .toPromise()
            .then((responseData: any) => {
              swal.hideLoading()
              if (responseData.hasOwnProperty('responseCode') && responseData.responseCode === '000') {
                if (this.selectedMethod == 'Mobile')
                  this.openMomoPaymentDialog();
                else if (this.selectedMethod == 'Cash')
                  this.openCashPaymentDialog();
                else if (this.selectedMethod == 'Cheque')
                  this.openChequePaymentDialog();
                else if (this.selectedMethod == 'Card')
                  this.openCardPaymentDialog();
              }
              else {
                swal.fire({
                  title: 'Wrong credentials!',
                  text: 'Please check and try again',
                  icon: 'error',
                });
                setTimeout(() => { this.confirmPin() }, 2000);
              }

            }).catch(error => {
              swal.fire({
                title: 'Connection Failed!',
                text: 'Login Failed',
                icon: 'error',
              });
              setTimeout(() => { this.confirmPin() }, 2000);
            })
        }
        else
          this.alertSnackBar('No Pin was entered', 'User Not Verified');
      });
  }


  //open momo Payment Dialog
  openMomoPaymentDialog() {
    const dialogRef = this.dialog.open(MomoPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: this.quote, total_premium: this.quote.total_premium_cv, policy_lob: "20" }
    });
  }


  //open card Payment Dialog
  openCardPaymentDialog() {
    const dialogRef = this.dialog.open(DebitCardPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: this.quote, total_premium: this.quote.total_premium_cv, policy_lob: "20" }
    });

    // dialogRef.afterClosed().subscribe(result => {

    //   if (result.hasOwnProperty('data')) {
    //     this.confirmedQuote = result.data.quotePayload.module == 'PAID';

    //     if (this.confirmedQuote) {
    //       setTimeout(() => {
    //         this.router.navigate(['transactions']);
    //       }, 3000)
    //     }
    //   }

    // });
  }

  //open cash Payment Dialog
  openCashPaymentDialog() {
    const dialogRef = this.dialog.open(CashPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: this.quote, total_premium: this.quote.total_premium_cv, policy_lob: "20" }
    });
    // dialogRef.afterClosed().subscribe(result => {

    //   if (result.hasOwnProperty('data')) {
    //     this.confirmedQuote = result.data.quotePayload.module == 'PAID';
    //     this.validateForm();

    //     if (this.confirmedQuote) {
    //       setTimeout(() => {
    //         this.router.navigate(['transactions']);
    //       }, 3000)
    //     }
    //   }

    // });
  }
  //open Cheque Payment Dialog
  openChequePaymentDialog() {
    const dialogRef = this.dialog.open(ChequePaymentComponent, {
      disableClose: true,
      width: '600px',
      data: { quotePayload: this.quote, total_premium: this.quote.total_premium_cv, policy_lob: "20" }
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result.hasOwnProperty('data')) {
    //     this.confirmedQuote = result.data.quotePayload.module == 'PAID';
    //     this.validateForm();

    //     if (this.confirmedQuote) {
    //       setTimeout(() => {
    //         this.router.navigate(['transactions']);
    //       }, 3000)
    //     }
    //   }
    // });

  }

  //confirm quote
  confirmQuote() {
    let selectedRisk: any = this.scheduleData.filter((risk: scheduleType) => risk.TABLED_CODE == this.payload.risk);

    if (selectedRisk)
      selectedRisk = selectedRisk[0];

    let summary = {
      CLIENT_CODE: this.payload.client_code,
      INCEPTION_DATE: moment(this.quote.inception_date).format('DD/MM/YYYY'),
      EXPIRY_DATE: moment(this.quote.expiry_date).format('DD/MM/YYYY'),
      DATE_COMPLETED: moment().format('DD/MM/YYYY'),
      PREPARED_BY: this.payload.prepared_by,
      CUSTOMER_FIRST_NAME: this.payload.customer_first_name,
      CUSTOMER_LAST_NAME: this.payload.customer_last_name,
      CUSTOMER_EMAIL: this.payload.customer_email,
      CUSTOMER_PHONE: this.payload.customer_phone,
      YEAR_OF_MANUFACTURE: this.payload.year_of_manufacture,
      VEHICLE_REGISTRATION: this.payload.vehicle_registration,
      MAKE: this.payload.make,
      QUOTE_MODEL: this.payload.model,
      CUBIC_CAPACITY: this.payload.cubic_capacity,
      SUM_INSURED: this.payload.sum_insured,
      QUOTE_DAYS: (this.payload.duration_type == 'shortrate' ? this.payload.days : this.payload.days),
      DURATION_TYPE: this.payload.duration_type,
      EXCESS_TYPE_CODE: this.payload.excess_type_code,
      CURRENCY_CODE: this.payload.currency_code,
      RISK_DESCRIPTION: selectedRisk ? selectedRisk.TABLED_DESIGNATION : null,
      COST_OF_INSURANCE: this.quote.total_premium,
      TPPD_LIMIT: this.quote.tppd_value,
      MONTHS: this.payload.months ? this.payload.months : 1,
      PRODUCT_CODE: this.payload.product,
      INPUTTED_DISCOUNT: this.payload.inputted_discount,
      EXTRA_PA: this.quote.pa_value
    }

    const dialogRef = this.dialog.open(QuoteSummaryComponent, {
      disableClose: true,
      width: '500px',
      data: {
        flow: 'moto',
        hide_foreign_currency: 0,
        quote: summary,
        total_premium: this.quote.total_premium,
        foreign_currency: this.quote.foreign_currency,
        foreign_amount: this.quote.foreign_amount,
        confirmedQuote: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.confirmedQuote;
        this.choosePaymentMethod();
        this.validateForm();
      }
    });
  }





  onDaysChanged(days: number) {
    //days=!isNaN(+`${this.quote.days}`)?parseInt(this.quote.days):1;

    console.log("onDaysChanged>>>" + days)

    if (days >= 365) {
      days = 364;
      this.quote.quote_days = "365";
      this.quote.duration_type = "annual";

      this.daysTextFieldInput = "365";
      this.annual = true;

      console.log('Days changed is annual', days);

    } else {
      this.quote.duration_type = "shortrate";

      if (localStorage.getItem("motor-quote")) {
        this.daysTextFieldInput = days + 0
        this.annual = false

        this.quote.quote_days = this.daysTextFieldInput

      }


      console.log('Days changed is shortrate', days);
    }


    //restrict negative days
    if (days <= 0)
      this.quote.quote_days = '1';



    if (this.quote.inception_date) {
      let inception_date = this.quote.inception_date ? moment(this.quote.inception_date) : null;
      let expiry_date = moment(inception_date).add(days - 1, 'days').format('YYYY-MM-DD'); //minus 1 to days
      this.quote.expiry_date = expiry_date;
    }




  }








  /*=== //handle Access changes==*/
  transformAmount(element) {
    this.formattedAmount = this.currencyPipe.transform(this.formattedAmount, 'GHS ');
    element.target.value = this.formattedAmount;
  }

  onDaysInput(event) {

    let days = !isNaN(+`${event.target.value}`) ? parseInt(event.target.value) : 0;

    if (!days) {
      event.target.value = 1;
    }
    else if (days > 365)
      event.target.value = 365;

  }


  onBackClicked() {
    if (this.previousPram == "quotes") {
      this.router.navigate(["/quotes"])

    } else if (this.previousPram == "policies") {
      this.router.navigate(["/mypolicies"])

    } else if (this.previousPram == "renewals") {
      this.router.navigate(["/renewpolicy"])

    } else if (this.previousPram == "documents" || localStorage.getItem("quote_activity") == 'info_for_print') {
      this.router.navigate(["/policydocuments"])
    }
    else {
      this.router.navigate(["/products"])
    }

  }

  //http requests
  showSchedulesAndRisks() {
    this.service.getSchedulesAndRisks()
      .toPromise()
      .then((response: scheduleTypeResponse) => {
        if (response.responseCode == '000') {
          response.profile.map((schedule: any) => {
            this.scheduleData.push({ ...schedule, HIDE: false });
          })
        }
      }).catch(err => {
        //console.log('Schedules load error',err);
      });
  }

  //open customer creation
  openCustomerCreationDialog() {
    const dialogRef = this.dialog.open(CreateCustomerComponent, {
      disableClose: true,
      width: '800px',
      data: { newCustomerDetails: {} }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result.hasOwnProperty('data') && result.data.hasOwnProperty('customer_code'))
        this.quote = {
          ...this.quote,
          client_code: result.data.customer_code,
          type: result.data.type,
          customer_first_name: result.data.customer_first_name,
          customer_other_names: result.data.customer_other_names,
          customer_last_name: result.data.customer_last_name,
          date_of_birth: moment(result.data.date_of_birth).format('YYYY-MM-DD'),
          customer_email: result.data.customer_email ? result.data.customer_email : '',
          customer_phone: result.data.customer_phone,
          digital_address: result.data.digital_address,
          id_type: result.data.id_type,
          id_number: result.data.id_number,
          tin: result.data.tin
        }

      if (this.quote.type == '0')
        this.selectedCustomer.DISPLAY_SHORT_NAME = `${this.quote.customer_first_name},${this.quote.customer_last_name},${this.quote.customer_phone}`;
      else
        this.selectedCustomer.DISPLAY_SHORT_NAME = `${this.quote.customer_first_name},${this.quote.customer_phone}`;


    });
  }
  //http requests
  showCurrencies() {
    this.service.getCurrencies()
      .toPromise()
      .then((response: CurrencyResponse) => {
        if (response.responseCode == '000') {
          response.responseData.map((currency: Currency) => {
            this.currencyData.push(currency);
          })
        }
      }).catch(err => {
        //console.log('Currencies load error',err);
      });
  }

  showBodyTypes() {
    this.service.getBodyTypes()
      .toPromise()
      .then((response: BodyTypeResponse) => {
        if (response.responseCode == '000') {
          response.responseData.map((body: BodyType) => {
            this.bodyData.push(body);
          })

        }
      }).catch(err => {
        //console.log('Body Type load error',err);
      });
  }

  //run validation
  validateForm(input?: any): boolean {
    let hasError = false;
    console.log("hasError---->" + this.quote.customer_phone)
    this.errors = {
      client_code: false,
      customer_phone: false,
      date_of_birth: false,
      product: false,
      // schedule_code: false,
      inception_date: false,
      expiry_date: false,
      currency_code: false,
      customer_email: false
    }
    if (!this.quote.client_code) {
      hasError = true;
      this.errors = { ...this.errors, client_code: true };
      console.log("client_code---->" + hasError)
    }
    if (this.quote.customer_phone.trim().length == 0 || this.quote.customer_phone && !this.service.validatePhone(this.quote.customer_phone)) {
      hasError = true;
      this.errors = { ...this.errors, customer_phone: true };
      console.log("customer_phone---->" + hasError)
    }
    if (this.quote.currency_code.trim().length == 0) {
      hasError = true;
      this.errors = { ...this.errors, currency_code: true };
      console.log("currency_code---->" + hasError)
    }

    if (this.quote.customer_email && !this.service.validateEmail(this.quote.customer_email)) {
      hasError = true;
      this.errors = { ...this.errors, customer_email: true };
      console.log("customer_email---->" + hasError)
    }

    if (!this.quote.customer_date_of_birth) {
      hasError = true;
      this.errors = { ...this.errors, customer_date_of_birth: true };
    }

    // if (!(moment(this.quote.customer_date_of_birth).isValid() && moment(moment().format('YYYY-MM-DD')).diff(moment(this.quote.customer_date_of_birth).format('YYYY-MM-DD'), 'years') >= 18)) {
    //   hasError = true;
    //   this.errors = { ...this.errors, customer_date_of_birth: true };

    //   this.alertSnackBar(`Date of birth must be greater or equal to:${moment(this.dobStartAt).format('LL')}`, 'Invalid Date');
    // }

    if (!this.quote.product) {
      hasError = true;
      this.errors = { ...this.errors, product: true };
      console.log("product---->" + hasError)
    }

    // if (!this.quote.schedule_code) {
    //   hasError = true;
    //   this.errors = { ...this.errors, schedule_code: true };
    //   console.log("schedule_code---->" + hasError)
    // }

    if (!moment(this.quote.inception_date).isValid()) {
      hasError = true;
      this.errors = { ...this.errors, inception_date: true };
      console.log("inception_date---->" + hasError)
    }

    if (!moment(this.quote.expiry_date).isValid()) {
      hasError = true;
      this.errors = { ...this.errors, expiry_date: true };
      console.log("expiry_date---->" + hasError)
    }

    return hasError;

  }


  finalizeQuote = (policy_no) => {
    // console.log('gen_comments>>>>> ' +  this.quote.gen_comments)

    //finalizing the quote creation
    this.payload = {
      gen_comments: this.quote.gen_comments,
      prepared_by: this.payload.prepared_by,
      policy_sequence: policy_no,
      platform: "Webapp",
      module: this.appuser.role,
      status_of_client: this.renewal ? 'renew' : "new",
      vehicle_registration: this.quote.vehicle_registration,
      make: this.selectedMake.VEHICLE_MAKE_DESC,
      model: this.selectedModel.VEHICLE_MODEL_DESC,
      chasis_number: this.quote.chasis_number,
      year_of_manufacture: this.quote.year_of_manufacture.toString(),
      body_type_code: this.quote.body_type_code,
      seats_number: this.quote.seats.toString(),
      currency_code: this.quote.currency_code,
      cubic_capacity: this.quote.cubic_capacity.toString(),
      fleet: "",
      exchange_rate: parseFloat(this.quote.exchange_rate).toFixed(2).toString(),
      duration_type: "",
      sum_insured: this.quote.sum_insured.toString(),
      excess_type_code: (this.quote.excess_buy_back ? 'EIB' : 'ENA'),
      tppd_limit: this.quote.tppd_value ? this.quote.tppd_value : "0",
      legacy_policy_number: this.quote.legacy_policy_number,
      color: this.selectedColor.DISPLAY_SHORT_NAME,
      color_code: parseInt(this.selectedColor.TABLED_CODE),
      customer_first_name: this.quote.customer_first_name,
      customer_last_name: this.quote.customer_last_name,
      customer_other_names: "",
      customer_email: this.quote.customer_email ? this.quote.customer_email : '',
      customer_phone: this.quote.customer_phone,
      customer_date_of_birth: moment(this.quote.date_of_birth).format('DD/MM/YYYY'),
      customer_branch_code: this.appuser.agency,
      customer_type_code: this.quote.customer_type_code,
      additional_pa_amt: this.quote.pa_value ? this.quote.pa_value : "0",
      product: this.quote.product,
      risk: this.quote.schedule_code,
      fleet_number: "",
      age_added: this.quote.vehicle_age_loading ? '1' : '0',
      cc_added: this.quote.cc_loading ? '1' : '0',
      intermediary_number: "AGT-MAXk-0000022",
      intermediary_nic_registration_number: "NIC/MC/020",
      intermediary_first_name: this.appuser.first_name ? this.appuser.first_name : '',
      intermediary_last_name: this.appuser.last_name ? this.appuser.last_name : '',
      intermediary_other_names: "",
      intermediary_phone: this.appuser.phone,
      intermediary_email: this.appuser.email,
      intermediary_type_code: "04",
      intermediary_branch_code: this.appuser.agency,
      ncd_discount_year: this.quote.ncd_value ? this.quote.ncd_value : "0",
      inception_date: moment(this.quote.inception_date).format('DD/MM/YYYY'),
      expiry_date: moment(this.quote.expiry_date).format('DD/MM/YYYY'),
      make_code: parseInt(this.selectedMake.VEHICLE_MAKE_CODE),
      model_code: parseInt(this.selectedModel.VEHICLE_MODEL_CODE),
      mileage: this.quote.mileage,
      policy_agency: this.appuser.agency || '502',
      inputted_discount: this.quote.inputted_discount,
      inputted_loading: this.quote.inputted_loading
    }


    if (this.appuser.role == 'agent') {
      this.payload = {
        ...this.payload,
        agent_code: this.appuser.code.toString(),
        client_code: this.quote.client_code.toString(),
        broker_user_code: '',
      }
    }
    else if (this.appuser.role == 'client') {
      this.payload = {
        ...this.payload,
        agent_code: '',
        client_code: this.appuser.code.toString(),
        broker_user_code: '',
      }
    } else if (this.appuser.role == 'broker-user') {
      this.payload = {
        ...this.payload,
        agent_code: this.appuser.broker_user_main_broker_code.toString(),
        client_code: this.quote.client_code.toString(),
        broker_user_code: this.appuser.code.toString(),
      }
    }

    //pass lagacy values for renewals
    if (this.renewal)
      this.payload = {
        ...this.payload,
        legacy_policy_sequence: this.quote.legacy_policy_sequence,
        legacy_total_premium: this.quote.legacy_total_premium,
        legacy_tppd_limit: this.quote.legacy_tppd_limit,
        legacy_excess_type_code: this.quote.legacy_excess_type_code,
        legacy_cc_added: this.quote.legacy_cc_added,
        legacy_age_added: this.quote.legacy_age_added,
        legacy_additional_pa_amt: this.quote.legacy_additional_pa_amt
      }
    //end lagacy values for renewals
    if (this.quote.days_label.toLowerCase() == 'months' || this.quote.days_label.toLowerCase() == 'month(s)')
      this.payload = {
        ...this.payload,
        months: this.quote.quote_days
      }
    else
      this.payload = {
        ...this.payload,
        days: this.quote.quote_days
      }


    let selectedBody: any = this.bodyData.filter(r => r.TABLED_CODE == this.quote.body_type_code);

    if (selectedBody)
      this.payload.body_type_description = selectedBody[0].TABLED_DESIGNATION

    if (this.quote.duration_type == 'annual')
      this.payload.duration_type = 'annual';
    else if (this.quote.duration_type == 'shortrate')
      this.payload.duration_type = 'shortrate';
    else
      this.payload.duration_type = 'prorata';

    this.service.swalAlart.showLoading();
    this.service.submitMotoQuote(this.payload)
      .toPromise()
      .then((response: any) => {
        if (response['responseCode'] && response['responseCode'] == 'E54')
          this.service.swalAlart.fire({
            title: 'Error',
            text: 'Sorry, this policy is not due for renewal',
            icon: 'error',
          });
        else if (response.responseCode == 'E56')
          this.service.swalAlart.fire({
            title: 'Error',
            text: "Sorry, we couldn't find any branch associated with this account. Kindly contact your branch manager.",
            icon: 'error',
          });
        else if (response.responseCode == 'E57')
          this.service.swalAlart.fire({
            title: 'Error',
            text: "Sorry, you cannot renew policy at this branch,kindly contact your branch office",
            icon: 'error',
          });
        else {
          this.quote.total_premium = response.responseConvertedValue ? response.responseConvertedValue.toString().replace(",", "").trim() : '0';
          this.quote.foreign_currency = response.responseCurrencyValue;
          this.quote.foreign_amount = response.responseData.toString().replace(",", "").trim();
          this.confirmQuote();
          this.service.swalAlart.close();
          this.processing = false;
        }

      }).
      catch(error => {
        //console.log('submit error',error)
        this.processing = false;
        this.service.swalAlart.close()
        this.service.swalAlart.fire({
          title: 'Error',
          text: 'Server Error,retry later',
          icon: 'error',
        });
      })
  }

  dobFilter(date: Date) {
    return moment(moment().format('YYYY-MM-DD')).diff(moment(date).format('YYYY-MM-DD'), 'years') >= 18;
  };
  
  //trigger the snackbar
  alertSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  //autocomplete search customer
  onFilterCustomer() {
    this.searchCustomerCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredCustomers = [];
          this.isCustomerLoading = true;
        }),
        switchMap(value => {
          if (value && value.length) {

            if (this.appuser.role == "agent") {
              this.filterClientPayload = {
                ...this.filterClientPayload,
                agent_code: this.appuser.code,
                search_parameter: value,
                module: 'agent'
              }

            } else if (this.appuser.role == "broker-user") {
              this.filterClientPayload = {
                ...this.filterClientPayload,
                broker_user_code: this.appuser.code,
                search_parameter: value,
                module: 'broker-user'
              }

            }else if (this.appuser.role == "staff") {
              this.filterClientPayload = {
                ...this.filterClientPayload,
                search_parameter: value,
                module: 'staff'
              }

            }

            console.log("filterClientPayload>>>>>>" + this.filterClientPayload)
            return this.service.searchCustomer(this.filterClientPayload)
              .pipe(
                finalize(() => {
                  this.isCustomerLoading = false
                }),
              )
          } else {
            this.isCustomerLoading = false
            return new Observable<[]>();
          }


        }
        )
      )
      .subscribe((response: CustomerResponse) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {

          if (this.appuser.role == "staff") {
            response.responseData.map(r => {

              r.CLIENT_DATE_OF_BIRTH = this.service.strDateToDBFormat(r.CLIENT_DATE_OF_BIRTH);
  
              let name_parts = [];
              if (r.CLIENT_FIRST_NAME)
                name_parts.push(r.CLIENT_FIRST_NAME);
  
              if (r.CLIENT_FAMILY_NAME)
                name_parts.push(r.CLIENT_FAMILY_NAME);
  
              if (r.CLIENT_HOME_MOBILE)
                name_parts.push(r.CLIENT_HOME_MOBILE);
  
              //if(r.CLIENT_CODE)
              //name_parts.push(`(${r.CLIENT_CODE})`);
  
              r.DISPLAY_NAME = name_parts.join(',');
  
              /*  let temp=name_parts[0];
               name_parts[0]=name_parts[name_parts.length-1];
               name_parts[name_parts.length-1]=temp;
  
               r.DISPLAY_SHORT_NAME=name_parts.slice(0,name_parts.length-1).join(','); */
              r.DISPLAY_SHORT_NAME = r.DISPLAY_NAME;
  
              r.isNewCustomer = false;
  
  
              this.filteredCustomers.push(r);
            })

          }else{
            response.profile.map(r => {

              r.CLIENT_DATE_OF_BIRTH = this.service.strDateToDBFormat(r.CLIENT_DATE_OF_BIRTH);
  
              let name_parts = [];
              if (r.CLIENT_FIRST_NAME)
                name_parts.push(r.CLIENT_FIRST_NAME);
  
              if (r.CLIENT_FAMILY_NAME)
                name_parts.push(r.CLIENT_FAMILY_NAME);
  
              if (r.CLIENT_HOME_MOBILE)
                name_parts.push(r.CLIENT_HOME_MOBILE);
  
              //if(r.CLIENT_CODE)
              //name_parts.push(`(${r.CLIENT_CODE})`);
  
              r.DISPLAY_NAME = name_parts.join(',');
  
              /*  let temp=name_parts[0];
               name_parts[0]=name_parts[name_parts.length-1];
               name_parts[name_parts.length-1]=temp;
  
               r.DISPLAY_SHORT_NAME=name_parts.slice(0,name_parts.length-1).join(','); */
              r.DISPLAY_SHORT_NAME = r.DISPLAY_NAME;
  
              r.isNewCustomer = false;
  
  
              this.filteredCustomers.push(r);
            })
          }

       




        }




      });
  }

  //autocomplete search make
  onLoadMake() {
    this.filteredMakes = [];
    this.loadedMakes = [];

    this.service.searchMake(this.service.currentUser().role == 'agent' ? this.service.currentUser().code : '', '', this.service.currentUser().role == 'agent' ? '' : this.service.currentUser().code)
      .toPromise()
      .then((response: MakeResponse) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {
          response.responseData.map((response: Make) => {
            let make: Make = { ...response, DISPLAY_SHORT_NAME: `${response.VEHICLE_MAKE_DESC}` };
            this.loadedMakes.push(make);
            this.filteredMakes.push(make);
          })
        }
      });

  }
  onFilterMake() {
    this.searchMakeCtrl.valueChanges
      .pipe(
        debounceTime(0),
        tap(() => {
          this.filteredMakes = [];
          this.isMakeLoading = true;
        }),
        switchMap(value => {
          if (value.length) {
            return Observable.create(observer => {
              let payload = this.loadedMakes.filter((response: Make) => response.DISPLAY_SHORT_NAME.trim().toLowerCase().includes(value.trim().toLowerCase()) == true)
              observer.next(payload)
            })
              .pipe(
                finalize(() => {
                  this.isMakeLoading = false
                }),
              )
          }
          else {
            this.isMakeLoading = false
            return new Observable<[]>();
          }
        }
        )
      )
      .subscribe((models: any) => {
        if (models)
          models.map((response: Make) => {

            this.filteredMakes.push({ ...response, DISPLAY_SHORT_NAME: `${response.VEHICLE_MAKE_DESC}` });
          })
      })
  }
  //load models
  onLoadModel() {
    this.filteredModels = [];
    this.loadedModels = [];
    this.service.searchModel(this.service.currentUser().role == 'agent' ? this.service.currentUser().code : '', this.selectedMake.VEHICLE_MAKE_CODE, '', this.service.currentUser().role == 'agent' ? '' : this.service.currentUser().code)
      .toPromise()
      .then((response: any) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {
          response.responseData.map((model: Model) => {
            //console.log('loaded models',model)
            model.DISPLAY_SHORT_NAME = model.VEHICLE_MODEL_DESC;
            this.loadedModels.push(model);
            this.filteredModels.push({ ...model, DISPLAY_SHORT_NAME: `${model.VEHICLE_MODEL_DESC}` });

          });
        }
      })
  }
  //autocomplete search model
  onFilterModel() {
    this.searchModelCtrl.valueChanges
      .pipe(
        debounceTime(0),
        tap(() => {
          this.filteredModels = [];
          this.isModelLoading = true;
        }),
        switchMap((value: string) => {
          if (value) {

            return Observable.create(observer => {
              let payload = this.loadedModels.filter((response: Model) => response.DISPLAY_SHORT_NAME.trim().toLowerCase().includes(value.trim().toLowerCase()) == true)
              observer.next(payload)
            })
              .pipe(
                finalize(() => {
                  this.isModelLoading = false
                }),
              )
          }
          else {
            this.isModelLoading = false;
            this.filteredModels = this.filteredModels;
            return new Observable<[]>();
          }
        }
        )
      ).subscribe((models: any) => {
        if (models)
          models.map((response: Model) => {

            this.filteredModels.push({ ...response, DISPLAY_SHORT_NAME: `${response.VEHICLE_MODEL_DESC}` });
          })
      })
  }
  //autocomplete search color
  onFilterColor() {
    this.searchColorCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredColors = [];
          this.isColorLoading = true;
        }),
        switchMap(value => {
          if (value.length)
            return this.service.searchColor(value)
              .pipe(
                finalize(() => {
                  this.isColorLoading = false
                }),
              )
          else {
            this.isColorLoading = false
            return new Observable<[]>();
          }
        }
        )
      )
      .subscribe((response: colorResponse) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {

          response.responseData.map((response: Color) => {
            this.filteredColors.push({ ...response, DISPLAY_SHORT_NAME: `${response.TABLED_DESIGNATION}` })
          })
        }
      });
  }

  //selected selected customer
  onSelectCustomer(customer: CustomerProfile) {
    this.errors = { ...this.errors, customer_code: false };

    this.selectedCustomer = customer;
    this.quote.client_code = this.selectedCustomer.CLIENT_CODE
    this.quote.customer_first_name = this.selectedCustomer.CLIENT_FIRST_NAME ? this.selectedCustomer.CLIENT_FIRST_NAME : this.selectedCustomer.CLIENT_FAMILY_NAME
    this.quote.customer_last_name = this.selectedCustomer.CLIENT_FAMILY_NAME
    this.quote.customer_phone = this.selectedCustomer.CLIENT_HOME_MOBILE
    this.quote.customer_email = (this.selectedCustomer.CLIENT_HOME_EMAIL ? this.selectedCustomer.CLIENT_HOME_EMAIL : '')
    this.quote.customer_date_of_birth = this.selectedCustomer.CLIENT_DATE_OF_BIRTH
    this.quote.customer_type_code = this.selectedCustomer.CLIENT_TYPE
  }


  
  onSelectMake(make: Make) {
    //console.log('make selected',make)
    this.errors = { ...this.errors, make: false };

    this.selectedMake = make;
    this.quote.make_code = this.selectedMake.VEHICLE_MAKE_CODE
    this.quote.make = this.selectedMake.VEHICLE_MAKE_DESC

    this.onLoadModel()
  }

  //selected selected customer
  onSelectModel(model: Model) {
    //console.log('model selected',model)

    this.errors = { ...this.errors, model: false };

    this.selectedModel = model;
    this.quote.model_code = this.selectedModel.VEHICLE_MODEL_CODE
    this.quote.model = this.selectedModel.VEHICLE_MODEL_DESC
  }
  //selected selected color
  onSelectColor(color: Color) {
    this.errors = { ...this.errors, color: false };
    this.selectedColor = color;
    this.quote.color_code = this.selectedColor.TABLED_CODE
    this.quote.color = this.selectedColor.TABLED_DESIGNATION
  }

  //customer change event
  onCustomerChange(event) {
    if (!event.target.value) {
      this.selectedCustomer = {
        CLIENT_CODE: "",
        CLIENT_FAMILY_NAME: "",
        CLIENT_HOME_MOBILE: "",
        CLIENT_FIRST_NAME: "",
        CLIENT_HOME_EMAIL: "",
        CLIENT_DATE_OF_BIRTH: "",
        DISPLAY_NAME: "",
        DISPLAY_SHORT_NAME: "",
        isNewCustomer: false
      }

      this.quote.customer_first_name = this.selectedCustomer.CLIENT_FIRST_NAME
      this.quote.customer_last_name = this.selectedCustomer.CLIENT_FAMILY_NAME
      this.quote.customer_phone = this.selectedCustomer.CLIENT_HOME_MOBILE
      this.quote.customer_email = (this.selectedCustomer.CLIENT_HOME_EMAIL ? this.selectedCustomer.CLIENT_HOME_EMAIL : '')
      this.quote.customer_date_of_birth = this.selectedCustomer.CLIENT_DATE_OF_BIRTH

    }
  }

  //make change event
  onMakeChange(event) {
    if (!event.target.value) {
      this.selectedMake = {
        VEHICLE_MAKE_CODE: "",
        VEHICLE_MAKE_DESC: "",
        DISPLAY_SHORT_NAME: ""
      }
      this.quote.make_code = this.selectedMake.VEHICLE_MAKE_CODE
      this.quote.make = this.selectedMake.VEHICLE_MAKE_DESC
    }
    else
      this.filteredModels = [];
  }
  //make change event
  onModelChange(event) {
    if (!event.target.value) {
      this.selectedModel = {
        VEHICLE_MODEL_CODE: "",
        VEHICLE_MODEL_MAKE_CODE: "",
        VEHICLE_MODEL_DESC: "",
        VEHICLE_MODEL_SECOND_DESC: "",
        DISPLAY_SHORT_NAME: ""
      }
      this.quote.model_code = this.selectedModel.VEHICLE_MODEL_CODE
      this.quote.model = this.selectedModel.VEHICLE_MODEL_DESC
    }
    else
      this.filteredModels = [];
  }
  onModelClick(event) {
    if (!event.target.value)
      this.filteredModels = this.loadedModels;
  }

  //color change event
  onColorChange(event) {
    if (!event.target.value) {
      this.selectedColor = {
        TABLED_NAME: "",
        TABLED_CODE: "",
        TABLED_DESIGNATION: "",
        DISPLAY_SHORT_NAME: ""
      }
      this.quote.color_code = this.selectedColor.TABLED_CODE
      this.quote.color = this.selectedColor.TABLED_DESIGNATION
    }
  }




  onUploadData() {
    const dialogRef = this.dialog.open(uploadExcelDataDialogComponent, {
      disableClose: false,
      width: '377px',
      data: { module: '' }
    });
    dialogRef.afterClosed().subscribe(result => {
    });

  }


  onAddVehicle() {
    console.log("onAddVehicle---->" + this.validateForm())
    if (this.validateForm()) {
      this.service.swalAlart.fire({
        title: 'Validation Alert',
        text: 'Kindly complete the highlighted fields',
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      })
    } else if (this.quote.channel=='1' && this.quote.client_code.length < 1){
      this.service.swalAlart.fire({
        title: 'Validation Alert',
        text: 'Provide client code',
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      })
    } else if (this.quote.channel=='5' && this.sob.agent_code.length < 1){
      this.service.swalAlart.fire({
        title: 'Validation Alert',
        text: 'Provide agent code',
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      })
    } else if (this.quote.channel=='2' && this.sob.broker_code.length < 1){
      this.service.swalAlart.fire({
        title: 'Validation Alert',
        text: 'Provide broker code',
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      })
    }
    
    
    
    else {
      //save quote details to localstorage
      this.quote.inception_date = moment(this.quote.inception_date).format('DD/MM/YYYY')
      this.quote.expiry_date = moment(this.quote.expiry_date).format('DD/MM/YYYY')
      this.quote.customer_date_of_birth = moment(this.quote.customer_date_of_birth).format('DD/MM/YYYY')

      //init quote object params
      this.quote.quote_objects = [
        {
          objecth_sequence: null,
          objecth_id: null,
          vehicle_registration: "",
          make: "",
          make_code: null,
          quote_model: "",
          model_code: null,
          chasis_number: "",
          body_type_code: "",
          body_type_description: "",
          seats: null,
          year_of_manufacture: "",
          cubic_capacity: null,
          mileage: null,
          sum_insured: 0.00,
          sum_insured_rate: 0.00,
          sum_insured_rate_cv: 0.00,
          total_premium: 0.00,
          total_premium_cv: 0.00,
          color: "",
          color_code: "",
          risk: "",
          object_days: 365,
          object_months: null,
          inception_date: "",
          expiry_date: "",
          excess_amount: 0,
          excess_type_code: "ENA",
          legacy_excess_type_code: "ENA",
          tppd_limit: 0.00,
          tppd_rate: 0.00,
          legacy_tppd_limit: 0.00,
          additional_pa_amt: 0.00,
          additional_pa_charge: 0.00,
          legacy_additional_pa_amt: 0.00,
          personal_accident_charge: 0.00,
          additional_loading_amt: 0.00,
          additional_loading_rate: 0.00
        }
      ]
    

      if (this.appuser.role == 'staff'){
          if(this.quote.channel == '5'){
            this.quote.agent_code = this.sob.agent_code
            this.quote.prepared_by = this.appuser.first_name
            this.quote.intermediary_first_name = this.sob.agent_family_name
            this.quote.intermediary_last_name = ''
            this.quote.intermediary_phone = ''
            this.quote.intermediary_email = this.sob.agent_work_email
            this.quote.policy_branch = this.sob.policy_branch_code
            this.quote.policy_agency = this.sob.policy_branch_code
            this.quote.policy_office = this.sob.policy_branch_code

            console.log("policy_branch===> " + JSON.stringify(this.sob.policy_branch_code))

          }else if(this.quote.channel == '2'){
            this.quote.agent_code = this.sob.broker_code
            this.quote.prepared_by = this.appuser.first_name
            this.quote.intermediary_first_name = this.sob.agent_family_name
            this.quote.intermediary_last_name = ''
            this.quote.intermediary_phone = ''
            this.quote.intermediary_email = this.sob.agent_work_email
            this.quote.policy_branch = this.sob.policy_branch_code
            this.quote.policy_agency = this.sob.policy_branch_code
            this.quote.policy_office = this.sob.policy_branch_code

            console.log("policy_branch===> " + JSON.stringify(this.sob.policy_branch_code))

          }
          else{
            this.quote.policy_branch = this.sob.policy_branch_code
            this.quote.policy_branch = this.sob.policy_branch_code
            this.quote.policy_agency = this.sob.policy_branch_code
            this.quote.policy_office = this.sob.policy_branch_code

          }
          
      }


      console.log("motor-quote===> " + JSON.stringify(this.quote))

      localStorage.setItem('motor-quote', JSON.stringify(this.quote))
      localStorage.setItem('objecth_position', "0")
      localStorage.setItem('objecth_position', "0")

      localStorage.removeItem('motor-quote-edit')

      console.log("quote_activity: FleetMotorr===>" + localStorage.getItem("quote_activity"))

      if (localStorage.getItem("quote_activity") == 'new' || localStorage.getItem("quote_activity") == 'new_add') {
        localStorage.setItem('quote_activity', 'new_add')

      } else if (localStorage.getItem("quote_activity") == 'edit_add' || localStorage.getItem("quote_activity") == 'edit') {
        localStorage.setItem('quote_activity', 'edit_add')

      } else if (localStorage.getItem("quote_activity") == 'policy_renewals' || localStorage.getItem("quote_activity") == 'policy_renewals_edit') {
        localStorage.setItem('quote_activity', 'policy_renewals_edit_add')
      }



      this.router.navigate(["/fleet-motor-add-vehicle"])



    }


  }

  onDownloadTemplate() {
    console.log("Download excel template")
  }


  openQuoteSummaryDialog(objecth_position: any, objecth_id: any) {
    console.log('objecth_position>>>>>>>>', objecth_position)
    // const selectedVehicle = this.quote.quote_objects.find((r: any) => r.objecth_id === objecth_id);
    // console.log('selectedVehicle>>>>>>>>', JSON.stringify(selectedVehicle))

    console.log('Vehicle Info', JSON.stringify(this.quote.quote_objects[objecth_position]))
    console.log('total_premium_cv', this.quote.quote_objects[objecth_position].total_premium_cv)
    console.log('Policy Info', this.quote)

    const dialogRef = this.dialog.open(QuoteSummaryFleetComponent, {
      disableClose: true,
      width: '500px',
      data: {
        flow: 'moto',
        hide_foreign_currency: 0,
        quote: this.quote.quote_objects[objecth_position],
        policyInfo: this.quote,

        total_premium: this.quote.quote_objects[objecth_position].total_premium_cv,
        foreign_currency: this.quote.currency_code,
        foreign_amount: this.quote.quote_objects[objecth_position].total_premium,
        confirmedQuote: false
      }
    });

    dialogRef.afterClosed().subscribe(result => { });

  }


  editQuoteSummaryDialog(objecth_position: string, objecth_id: string) {
    if (this.validateForm()) {
      this.service.swalAlart.fire({
        title: 'Validation Alert',
        text: 'Kindly complete the highlighted fields',
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      })
    } else {
      //save quote details to localstorage
      this.quote.inception_date = moment(this.quote.inception_date).format('DD/MM/YYYY')
      this.quote.expiry_date = moment(this.quote.expiry_date).format('DD/MM/YYYY')
      this.quote.customer_date_of_birth = moment(this.quote.customer_date_of_birth).format('DD/MM/YYYY')


      console.log("objecth_position>>>>>" + objecth_position)
      console.log("objecth_id>>>>>" + objecth_id)

      localStorage.setItem('objecth_position', objecth_position)
      localStorage.setItem('objecth_id', objecth_id)
      //console.log("motor-quote-edit>>>>>" + JSON.stringify(selectedVehicle))

      localStorage.setItem('motor-quote', JSON.stringify(this.quote))

      console.log("quote_activity: Fleet Motor Edit>>>>" + localStorage.getItem("quote_activity"))

      if (localStorage.getItem("quote_activity") == 'new') {
        localStorage.setItem('quote_activity', 'new_edit')

      } else if (localStorage.getItem("quote_activity") == 'edit' || localStorage.getItem("quote_activity") == 'edit_add') {
        localStorage.setItem('quote_activity', 'edit')

      } else if (localStorage.getItem("quote_activity") == 'policy_renewals' || localStorage.getItem("quote_activity") == 'policy_renewals_edit_add') {
        localStorage.setItem('quote_activity', 'policy_renewals_edit')
      }

      this.router.navigate(['/fleet-motor-add-vehicle']);
      //this.router.navigate(['/fleet-motor-add-vehicle'],{state:{editableQuote:selectedVehicle}});
    }
  }


  deleteVehicleDialog(objecth_id: number) {
    this.service.swalAlart.fire({
      title: '',
      icon: 'info',
      text: 'Do you want to remove this vehicle?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No, thanks!',
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.value) {

        console.log("objecth_id>>>>>" + objecth_id)

        this.deleteVehicle(this.quote, objecth_id)

      } else {
        result.dismiss === this.service.swalAlart.DismissReason.cancel

      }

    })
  }



  deleteVehicle(postPayload: any, objecth_id: number) {

    postPayload.quote_activity = "Delete"
    const deletedVehicleObject = this.quoteDetails[0].quote_objects.find((element: any) => element.objecth_id === objecth_id);
    postPayload.quote_objects = []
    postPayload.quote_objects.push(deletedVehicleObject)
    postPayload.product_description = null


    console.log("payload>>>>>" + JSON.stringify(postPayload))

    postPayload.customer_date_of_birth = moment(postPayload.customer_date_of_birth).format('DD/MM/YYYY'),
      postPayload.inception_date = moment(postPayload.inception_date).format('DD/MM/YYYY'),
      postPayload.expiry_date = moment(postPayload.expiry_date).format('DD/MM/YYYY'),


      this.service.swalAlart.showLoading()
    this.service.deleteVehicle(postPayload)
      .toPromise()
      .then((response: any) => {
        this.service.swalAlart.close()

        this.responseCode = response.responseCode
        this.responseMessage = response.responseMessage

        if (response.responseCode == '000') {
          this.loadQuoteVehicles()

          swal.fire({
            icon: 'success',
            text: 'Vehicle removed',
            timer: 5000,
            showConfirmButton: false
          });

        } else {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: this.responseMessage,
            icon: 'error',
          });
        }

      }).catch(error => {
        //console.log('Error',error)
        this.processing = false;
        this.service.swalAlart.close();
        this.service.swalAlart.fire({
          title: 'Server Error',
          text: this.responseMessage,
          icon: 'error',
        });
      })

  }


  onSelectPolicy(policy) {
    this.selectedPolicy = policy;
  }

  onPrinkSticker() {
    let stickerURL = this.quote.quote_objects[this.selectedPolicy].sticker_link
    if (stickerURL) {
      window.open(stickerURL, '_blank')
    } else {
      this.service.swalAlart.fire({
        icon: "error",
        text: "No Sticker found",
        confirmButtonText: "Ok"
      })
    }
  }

  onPrinkCertificate() {
    let certificateURL = this.quote.quote_objects[this.selectedPolicy].certificate_link
    if (certificateURL) {
      window.open(certificateURL, '_blank')
    } else {
      this.service.swalAlart.fire({
        icon: "error",
        text: "No Certificate found",
        confirmButtonText: "Ok"
      })
    }
  }







  transactionStatus(){
    let payload: any = {}

    if(this.appuser.role=='agent'){
      payload={
        agent_code:this.appuser.code,
        client_code:this.quotePayloadBeforePayment.client_code,
        module:'agent',
        transaction_token:this.quotePayloadBeforePayment.transactionToken,
        policy_sequence:this.quotePayloadBeforePayment.policy_sequence,
        platform:'webapp',
        policy_lob: this.quotePayloadBeforePayment.policy_lob,
      }
    }else if(this.appuser.role=='broker-user'){
      payload={
        agent_code:this.appuser.broker_user_main_broker_code,
        broker_user_code:this.appuser.code,
        client_code:this.quotePayloadBeforePayment.client_code,
        module:'broker-user',
        transaction_token:this.quotePayloadBeforePayment.transactionToken,
        policy_sequence:this.quotePayloadBeforePayment.policy_sequence,
        platform:'webapp',
        policy_lob: this.quotePayloadBeforePayment.policy_lob,
      
      }
  
    }else if(this.appuser.role=='client'){
      payload={
        client_code:this.appuser.code,
        module:'client',
        transaction_token:this.quotePayloadBeforePayment.transactionToken,
        policy_sequence:this.quotePayloadBeforePayment.policy_sequence,
        platform:'webapp',
        policy_lob: this.quotePayloadBeforePayment.policy_lob,
      }

    }else if (this.appuser.role=='staff'){
      payload={
        module:'staff',
        staff: {
          staff_id:this.appuser.code
        },
        transaction_token:this.quotePayloadBeforePayment.transactionToken,
        policy_sequence:this.quotePayloadBeforePayment.policy_sequence,
        platform:'webapp',
        policy_lob: this.quotePayloadBeforePayment.policy_lob,
      }
    }
   

    this.service.swalAlart.showLoading()
    this.service.manualQuery(payload)
    .toPromise()
    .then((response:any)=>{
      this.service.swalAlart.close()
        if(response.hasOwnProperty('responseCode') && response.responseCode=='000'){
          this.service.swalAlart.close();

          console.log("policy_lob===> " + payload.policy_lob);
        
          if(payload.policy_lob === "20"){
            localStorage.removeItem('quotePayload')

            this.service.swalAlart.fire({
              title: 'Payment successful',
              html: response.hasOwnProperty('stickerUrl')? `SMS and Email sent with Policy details to print<br><br>Kindly print <a target='_blank' href='${response.stickerUrl}'>Sticker</a> and <a target='_blank' href='${response.certificateUrl}'>Certificate</a>`:`Payment completed successfully;your certificate has been mailed to you!<br><br/>Kindly click the <a target='_blank' href='${response.certificateUrl}'>Preview</a> link to view certificate`,
              icon: 'success',
              confirmButtonText:'Done',
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            .then((result)=>{
            if(result){
              this.router.navigate(['transactions']);
              this.dialogRef.close()
            }
              
            });
          }else if (payload.policy_lob === "50"){
            localStorage.removeItem('quotePayload')

            this.service.swalAlart.fire({
              title: 'Payment successful',
              html: `SMS and email sent with policy details to print<br><br><a target='_blank' href='${response.certificateUrl}?print_form=0'>Click here to print <b>preprinted certificate</b></a><br><br><a target='_blank' href='${response.certificateUrl}?print_form=1'>Click here to print <b>normal certificate</b></a><br><br><a target='_blank' href='${response.travelDocUrl}'>Click here to print <b>travel documents</b></a>`,
              icon: 'success',
              confirmButtonText:'Done',
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((result)=>{
              if(result){
                this.router.navigate(['transactions']);
                this.dialogRef.close();
              }
             });

          }else{
            localStorage.removeItem('quotePayload')

            this.service.swalAlart.fire({
              title: 'Payment successful',
              html: `SMS and email sent with policy details to print<br><br><a target='_blank' href='${response.certificateUrl}?print_form=0'>Click here to print <b>preprinted certificate</b></a><br><br><a target='_blank' href='${response.certificateUrl}?print_form=1'>Click here to print <b>normal certificate</b></a>`,
              icon: 'success',
              confirmButtonText:'Done',
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then((result)=>{
              if(result){
                this.router.navigate(['transactions']);
                this.dialogRef.close();
                
              }
             });
             
          }

       

        }else if(response.hasOwnProperty('responseCode') && response.responseCode=='E55'){

            this.openfinalizeMomoRetryPaymentDialog(payload)
            this.dialogRef.close();
          

        }else if (response.hasOwnProperty('responseCode') && response.responseCode=='001'){
          localStorage.removeItem('quotePayload')
          
          this.service.swalAlart.close();
          this.service.swalAlart.fire({
            title: 'Processing Payment...',
            html: 'Please wait. An sms and email of policy and sticker details will be sent to you after processing payment.',
            icon: 'success',
            confirmButtonText:'Done',
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false
          }).then((result)=>{
            if(result){
              this.router.navigate(['transactions']);
              this.dialogRef.close();
            }
           });

      }else if (response.hasOwnProperty('responseCode') && response.responseCode=='500'){
        this.service.swalAlart.close()
        this.service.swalAlart.fire({
          title: 'Failed',
          text: 'Server error',
          icon: 'error',
        });
      }
      else{

      }
    })
  }

     
  openfinalizeMomoRetryPaymentDialog(payload){
    const dialogRef = this.dialogRetryRef.open(FinalizeRetryMomoComponent, {
      disableClose :true,
      width: '350px',
      data: { quotePayload: payload}
    });
    dialogRef.afterClosed().subscribe((result:any) => {

      if(result.hasOwnProperty('data')){
        result.data.quotePayload.module=this.service.currentUser().role.toLowerCase();
        //console.log('Data',result.data)
      }
    });

  }





  onLoadAgentLOV() {
    this.filteredAgentLOV = [];
    this.AgentLOV = [];

    this.service.getAgents({})
      .toPromise()
      .then((response: any) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {
    //      console.log("companyData>>>> " + JSON.stringify(response.responseData))

          response.responseData.map((response: agentLOVData) => {
            let agent: agentLOVData = { ...response, agent_code: `${response.agent_code}` };
            this.AgentLOV.push(agent);
            this.filteredAgentLOV.push(agent);
          })
        }
      });

  }

  onFilterAgentLOV() {
    this.sob_agent_code.valueChanges
      .pipe(
        debounceTime(0),
        tap(() => {
          this.filteredAgentLOV = [];
          this.isAgentLOVLoading = true;
        }),
        switchMap(value => {
          if (value.length) {
            return Observable.create(observer => {
              let payload = this.AgentLOV.filter((response: agentLOVData) => response.agent_code.trim().toLowerCase().includes(value.trim().toLowerCase()) == true)
              observer.next(payload)

          
            })
              .pipe(
                finalize(() => {
                  this.isAgentLOVLoading = false
                }),
              )
          }
          else {
            this.isAgentLOVLoading = false
            return new Observable<[]>();
          }
        }
        )
      )
      .subscribe((models: any) => {
        if (models)
          models.map((response: agentLOVData) => {

            this.filteredAgentLOV.push({ ...response, agent_code: `${response.agent_code}` });
          })
      })
  }



  onAgentLOVChange(event) {
    if (!event.target.value) {
      console.log("sob.agent_code>>>>" +  event.target.value)

    } else {

    }

  }


  onSelectAgent(agent: agentLOVData) {
     this.sob.agent_code = agent.agent_code
     this.sob.agent_family_name = agent.agent_family_name
     this.sob.agent_work_email = agent.agent_work_email
     this.sob.policy_branch_code = agent.agent_agency

   }


   onLoadBrokerLOV() {
    this.filteredBrokerLOV = [];
    this.BrokerLOV = [];

    this.service.getBrokers({})
      .toPromise()
      .then((response: any) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {
    //      console.log("companyData>>>> " + JSON.stringify(response.responseData))

          response.responseData.map((response: brokerLOVData) => {
            let agent: brokerLOVData = { ...response, agent_code: `${response.agent_code}` };
            this.BrokerLOV.push(agent);
            this.filteredBrokerLOV.push(agent);
          })
        }
      });

  }

  onFilterBrokerLOV() {
    this.sob_broker_code.valueChanges
      .pipe(
        debounceTime(0),
        tap(() => {
          this.filteredBrokerLOV = [];
          this.isBrokerLOVLoading = true;
        }),
        switchMap(value => {
          if (value.length) {
            return Observable.create(observer => {
              let payload = this.BrokerLOV.filter((response: brokerLOVData) => response.agent_code.trim().toLowerCase().includes(value.trim().toLowerCase()) == true)
              observer.next(payload)
            })
              .pipe(
                finalize(() => {
                  this.isBrokerLOVLoading = false
                }),
              )
          }
          else {
            this.isBrokerLOVLoading = false
            return new Observable<[]>();
          }
        }
        )
      )
      .subscribe((models: any) => {
        if (models)
          models.map((response: agentLOVData) => {

            this.filteredBrokerLOV.push({ ...response, agent_code: `${response.agent_code}` });
          })
      })
  }

  onBrokerLOVChange(event) {
    if (!event.target.value) {
      console.log("sob.agent_code>>>>" +  event.target.value)

    } else {

    }

  }

  onSelectBroker(broker: brokerLOVData) {
     this.sob.broker_code = broker.agent_code
     this.sob.agent_family_name = broker.agent_family_name
     this.sob.agent_work_email = broker.agent_work_email
     this.sob.policy_branch_code = broker.agent_agency

   }



   onLoadBranchLOV() {
    this.filteredBranchLOV = [];
    this.BranchLOV = [];

    let payload = {
      staff_id : this.appuser.code
    }

    this.service.getClientBranches(payload)
      .toPromise()
      .then((response: any) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {
          response.responseData.map((response: branchLOVData) => {
            let branch: branchLOVData = { ...response, user_branch_code: `${response.user_branch_code}` };
            this.BranchLOV.push(branch);
            this.filteredBranchLOV.push(branch);
          })
        }
      });

  }

   onFilterPolicyBranchLOV() {
    this.sob_policy_branch_code.valueChanges
      .pipe(
        debounceTime(0),
        tap(() => {
          this.filteredBranchLOV = [];
          this.isBranchLOVLoading = true;
        }),
        switchMap(value => {
          if (value.length) {
            return Observable.create(observer => {
              let payload = this.BranchLOV.filter((response: branchLOVData) => response.user_branch_code.trim().toLowerCase().includes(value.trim().toLowerCase()) == true)
              observer.next(payload)
            })
              .pipe(
                finalize(() => {
                  this.isBranchLOVLoading = false
                }),
              )
          }
          else {
            this.isBranchLOVLoading = false
            return new Observable<[]>();
          }
        }
        )
      )
      .subscribe((models: any) => {
        if (models)
          models.map((response: branchLOVData) => {

            this.filteredBranchLOV.push({ ...response, user_branch_code: `${response.user_branch_code}` });
          })
      })
  }

  onPolicyBranchLOVChange(event) {
    if (!event.target.value) {
      console.log("policy branch>>>>" +  event.target.value)

    } else {

    }

  }

  onSelectPolicyBranch(branch: branchLOVData) {
     this.sob.policy_branch_code = branch.user_branch_code
     this.sob.policy_branch_name = branch.user_branch_desc

     console.log("this.sob.policy_branch_code>>>>" +  this.sob.policy_branch_code)
  
    }



   onUserTypeSelected(sob){
    console.log("sourceOfBusiness>>>> " + sob)

    this.sob_agent_code.setValue('')
    this.sob_broker_code.setValue('')
    this.sob_policy_branch_code.setValue('')
    this.sob.agent_code = '';
    this.sob.client_code = '';
    this.sob.broker_code = '';
    this.sob.agent_family_name = '';
    this.sob.agent_work_email = '';
    this.sob.policy_branch_code = '';




   }


}


