import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';

import { DashboardService } from '../service/dashboard.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import swal, { SweetAlertResult } from 'sweetalert2';
import { MatStepper } from '@angular/material/stepper';

import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ShowPremiumTotalComponent } from '../dialogs/show-premium-total/show-premium-total.component';
import { SelectPaymentMethodComponent } from '../dialogs/select-payment-method/select-payment-method.component';
import { CashPaymentComponent } from '../dialogs/cash-payment/cash-payment.component';
import { ChequePaymentComponent } from '../dialogs/cheque-payment/cheque-payment.component';
import { CreateCustomerComponent } from '../dialogs/create-customer/create-customer.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DebitCardPaymentComponent } from '../dialogs/debit-card-payment/debit-card-payment.component';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { format } from 'highcharts';
import { MomoPaymentComponent } from '../dialogs/momo-payment/momo-payment.component';
import { QuoteSummaryComponent } from '../dialogs/quote-summary/quote-summary.component';

interface CustomerProfile {
  CLIENT_CODE: string;
  CLIENT_TYPE?: string;
  CLIENT_FAMILY_NAME: string;
  CLIENT_HOME_MOBILE: string;
  CLIENT_FIRST_NAME: string;
  CLIENT_HOME_EMAIL?: string;
  CLIENT_DATE_OF_BIRTH?: string;
  DISPLAY_NAME?: string;
  DISPLAY_SHORT_NAME?: string;
  isNewCustomer?: boolean;
}
interface CustomerResponse {
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  responseData: any;
  profile: CustomerProfile[];
}
interface Make {
  VEHICLE_MAKE_CODE: string;
  VEHICLE_MAKE_DESC: string;
  DISPLAY_SHORT_NAME: string;
}
interface MakeResponse {
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: any;
  responseData: Make[];
}
interface Model {
  VEHICLE_MODEL_CODE: string;
  VEHICLE_MODEL_MAKE_CODE: string;
  VEHICLE_MODEL_DESC: string;
  VEHICLE_MODEL_SECOND_DESC: string;
  DISPLAY_SHORT_NAME: string;
}
interface ModelResponse {
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: any;
  responseData: Model[];
}
interface coverType {
  value: string;
  viewValue: string;
  hide: boolean;
}

interface scheduleTypeResponse {
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: scheduleType[];
}

interface scheduleType {
  TABLED_CODE: string;
  TABLED_NAME: string;
  TABLED_DESIGNATION: string;
  HIDE: boolean;
}

interface CurrencyResponse {
  responseCode: string;
  responseMessage: string;
  code: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: string;
  responseData: Currency[];
}

interface Color {
  TABLED_NAME: string;
  TABLED_CODE: string;
  TABLED_DESIGNATION: string;
  DISPLAY_SHORT_NAME: string;
}

interface colorResponse {
  responseCode: string;
  responseMessage: string;
  data: string;
  account_activation_requested: string;
  agent_pin_set: string;
  profile: string;
  responseData: Color[]
}

interface duration {
  value: string;
  viewValue: string;
  days: string;
  hide: boolean
}

interface BodyType {
  TABLED_CODE: string;
  TABLED_DESIGNATION: string;
}

interface QuoteResponse {
  responseCode: string;
  responseMessage: string;
  responseData: string;
  responseNumber: string;
}

interface BodyTypeResponse {
  responseCode: string;
  responseData: BodyType[];
}

interface year {
  value: string;
  viewValue: string;
}


interface Currency {
  CURRENCY: string;
  DATE_UPDATED: string;
  CURRENCY_VALUE: number;
}
interface accessList {
  value: string;
  viewValue: string;
}


var i: number;

//var arrayYear = [];


@Component({
  selector: 'app-fleet-motor-add-vehicle',
  templateUrl: './fleet-motor-add-vehicle.component.html',
  styleUrls: ['./fleet-motor-add-vehicle.component.scss'],
  providers: [DatePipe]
})



export class FleetMotorAddVehicleComponent implements OnInit {
  appuser: any;
  //customer
  searchCustomerCtrl = new FormControl();
  filteredCustomers: CustomerProfile[];

  //make
  searchMakeCtrl = new FormControl();
  loadedMakes: Make[];
  filteredMakes: Make[];

  //model
  searchModelCtrl = new FormControl();
  loadedModels: Model[] = [];
  filteredModels: Model[] = [];

  //color
  searchColorCtrl = new FormControl();
  filteredColors: Color[];

  //loadings
  isCustomerLoading: boolean = false;
  isMakeLoading: boolean = false;
  isModelLoading: boolean = false;
  isColorLoading: boolean = false;

  //is renewal
  renewal: boolean = false;
  annual: boolean = true;

  //payload for submitting quote
  payload: any = {};
  filterClientPayload: any = {};

  coverData: coverType[] = [
    { value: '203', viewValue: 'MOTOR THIRD PARTY ONLY', hide: false },
    { value: '201', viewValue: 'MOTOR COMPREHENSIVE', hide: false },
    { value: '202', viewValue: 'THIRD PARTY FIRE & THEFT', hide: false },
  ];

  dobStartAt = new Date(moment(moment().format('YYYY-MM-DD')).subtract(18, 'years').format('YYYY-MM-DD'))

  //schedules and risks
  scheduleData: scheduleType[] = [];

  isNewCustomer: true;
  bodyData: BodyType[] = [];

  durationData: duration[] = [
    { value: 'annual', viewValue: 'Annual Term', days: '365', hide: false },
    { value: 'shortrate', viewValue: 'Short Term', days: '1', hide: false },
    //{ value: '03', viewValue: 'Prorata Term', days: '1', hide: false }
  ];

  yearData: year[] = [];

  currencyData: Currency[] = []

  accessData: accessList[] = [
    { value: "0", viewValue: "0" },
    { value: "1", viewValue: "1" },
    { value: "2", viewValue: "2" },
    { value: "3", viewValue: "3" },
    { value: "4", viewValue: "4" },
    { value: "5", viewValue: "5" },
    { value: "6", viewValue: "Above 5" },
  ]

  //selected records from dialogs
  selectedCustomer: CustomerProfile = {
    CLIENT_CODE: "",
    CLIENT_TYPE: "",
    CLIENT_FAMILY_NAME: "",
    CLIENT_HOME_MOBILE: "",
    CLIENT_FIRST_NAME: "",
    CLIENT_HOME_EMAIL: "",
    CLIENT_DATE_OF_BIRTH: "",
    DISPLAY_NAME: "",
    DISPLAY_SHORT_NAME: "",
    isNewCustomer: false
  };
  selectedMake: Make = {
    VEHICLE_MAKE_CODE: "",
    VEHICLE_MAKE_DESC: "",
    DISPLAY_SHORT_NAME: ""
  }
  selectedModel: Model = {
    VEHICLE_MODEL_CODE: "",
    VEHICLE_MODEL_MAKE_CODE: "",
    VEHICLE_MODEL_DESC: "",
    VEHICLE_MODEL_SECOND_DESC: "",
    DISPLAY_SHORT_NAME: ""
  }
  selectedColor: Color = {
    TABLED_NAME: "",
    TABLED_CODE: "",
    TABLED_DESIGNATION: "",
    DISPLAY_SHORT_NAME: ""
  }
  //searchCustomerDialogValue :any;
  //prepare quote json
  confirmedQuote: boolean = false;
  errors = {}
  selectedMethod: string;

  //is processing
  processing = false;
  renewal_checked: any = "";




  quote: any = {
    policy_sequence: null,
    legacy_policy_sequence: null,
    legacy_policy_number: null,
    policy_company: null,
    policy_branch: '',
    policy_agency: '',
    policy_office: '',
    broker_user_code: null,
    policy_lob: "",
    policy_status: null,
    policy_status_reason: null,
    product: "",
    duration_type: "",
    agent_code: null,
    client_code: null,
    module: "",
    channel: "1",
    staff:{
          staff_id:"",
          staff_email:""
          },
    prepared_by: "",
    status_of_client: "new",
    status: "active",
    currency_code: "GHC",
    fleet: null,
    exchange_rate: 1.00,
    quote_days: 365,
    days_label: "Days",
    quote_months: null,
    inception_date: "",
    expiry_date: "",
    total_premium: 0.00,
    total_premium_cv: 0.00,
    customer_first_name: "",
    customer_last_name: "",
    customer_email: "",
    customer_phone: "",
    customer_date_of_birth: "",
    customer_type_code: "01",
    intermediary_first_name: "",
    intermediary_last_name: "",
    intermediary_email: "",
    intermediary_phone: "",
    product_description: null,
    platform: "Webapp",
    quote_objects: [{
      objecth_sequence: null,
      objecth_id: null,
      vehicle_registration: "",
      make: "",
      make_code: null,
      quote_model: "",
      model_code: null,
      chasis_number: "",
      body_type_code: "",
      body_type_description: "",
      seats: null,
      year_of_manufacture: "",
      cubic_capacity: null,
      mileage: null,
      sum_insured: 0.00,
      sum_insured_rate: 0.00,
      sum_insured_rate_cv: 0.00,
      total_premium: 0.00,
      total_premium_cv: 0.00,
      color: "",
      color_code: "",
      risk: "",
      object_days: 365,
      object_months: null,
      inception_date: "",
      expiry_date: "",
      excess_amount: 0,
      excess_type_code: "ENA",
      legacy_excess_type_code: "ENA",
      tppd_limit: 0.00,
      tppd_rate: 0.00,
      legacy_tppd_limit: 0.00,
      additional_pa_amt: 0.00,
      additional_pa_charge: 0.00,
      legacy_additional_pa_amt: 0.00,
      legacy_additional_loading: 0.00,
      legacy_discount_amt: 0.00,
      personal_accident_charge: 0.00,
      additional_loading_amt: 0.00,
      additional_loading_rate: 0.00
    }],
    date_updated: "",
    date_created: ""
  }



  selectedQuote: any;


  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  sixthFormGroup: FormGroup;
  isOptional = false;
  formattedAmount;
  amount;
  todayDate = new Date();
  dateTxt: string;
  daysTextFieldInput: any;
  isStaff = false
  fleetQuote: any

  responseCode: any
  responseMessage: any

  quote_activity: string = ""
  btnSubmitLbl: string = ""
  pageHeaderLabel: string = ""
  objecth_position: number = 0;
  objecth_id: number = 0;

  check_excess_buy_back: boolean = false;
  check_extra_tppd_limit: boolean = false;
  check_additional_pa: boolean = false;
  check_vehicle_age_loading: boolean = false;
  check_cc_loading: boolean = false;
  check_additional_loading: boolean = false;

  constructor(private datePipe: DatePipe, private _formBuilder: FormBuilder, private service: DashboardService, private router: Router, private currencyPipe: CurrencyPipe, public dialog: MatDialog, private _snackBar: MatSnackBar) {
    this.dateTxt = this.datePipe.transform(this.todayDate, 'dd-MM-yyyy');

    this.appuser = this.service.currentUser();

    this.quote_activity = localStorage.getItem("quote_activity")
    console.log("quote_activity: Fleet Motor Add>>>>" + this.quote_activity)

    if (this.quote_activity == 'new_add' || this.quote_activity == 'edit_add') {
      this.btnSubmitLbl = "Add Vehicle"
      this.pageHeaderLabel = "New Motor Insurance Quote - Fleet - Add Vehicle"

    } else if (this.quote_activity == 'edit') {

      this.btnSubmitLbl = "Edit Vehicle"
      this.pageHeaderLabel = "New Motor Insurance Quote - Fleet - Edit Vehicle"

    } else if (this.quote_activity == 'policy_renewals_edit_add') {
      this.btnSubmitLbl = "Add Vehicle"
      this.pageHeaderLabel = "Policy Renewal Insurance Quote - Fleet - Add Vehicle"

    } else if (this.quote_activity == 'policy_renewals_edit') {
      this.btnSubmitLbl = "Edit Vehicle"
      this.pageHeaderLabel = "Policy Renewal Insurance Quote - Fleet - Edit Vehicle"
    }


    if (!this.appuser)
      this.router.navigate(['']);

    this.renewal = false;
    this.selectedQuote = null;

    console.log("quote_activity>>>>" + this.quote_activity)

  }


  ngOnInit() {
    this.quote.inception_date = this.service.strDateToDBFormat(this.dateTxt)
    //this.quote.quote_days = this.durationData[0].days;
    //this.quote.duration_type = this.durationData[0].value;

    if (localStorage.getItem("motor-quote")) {
      this.quote = JSON.parse(localStorage.getItem("motor-quote"))

      // this.quote.inception_date = this.service.strDateToDBFormat(this.quote.inception_date)
      // this.quote.customer_date_of_birth = this.service.strDateToDBFormat(this.quote.customer_date_of_birth)

      // this.objecth_position = parseInt(localStorage.getItem("objecth_position"))
      // this.objecth_id = parseInt(localStorage.getItem("objecth_id"))
      // this.quote.quote_objects[this.objecth_position].risk = this.quote.quote_objects[this.objecth_position].risk.toString()

      this.populateQuoteValues()

    }

    if (localStorage.getItem("objecth_position") && localStorage.getItem("objecth_id")) {
      this.objecth_position = parseInt(localStorage.getItem("objecth_position"))
      this.objecth_id = parseInt(localStorage.getItem("objecth_id"))
      this.quote.quote_objects[this.objecth_position].risk = this.quote.quote_objects[this.objecth_position].risk.toString()

      if (this.quote.quote_objects[this.objecth_position].color == null) {
        this.quote.quote_objects[this.objecth_position].color = ''
      }



      //this.populateQuoteValues()
    }


    // if (history.state.renewal) {
    //   this.renewal = history.state.renewal
    //   this.renewal_checked = 'renew'
    // }

    console.log("appuser>>>>>>" + JSON.stringify(this.appuser))

    if (this.appuser.role == 'agent' || this.appuser.role == 'broker-user'){
      this.quote.agent_code = this.appuser.code
      this.quote.intermediary_first_name = this.appuser.first_name === null? this.appuser.last_name: this.appuser.first_name
      this.quote.intermediary_last_name = this.appuser.last_name === null? this.appuser.first_name: this.appuser.last_name
      this.quote.intermediary_phone = this.appuser.phone
      this.quote.intermediary_email = this.appuser.email
      this.quote.prepared_by = this.appuser.first_name + " " + this.appuser.last_name

      console.log("intermediary_first_name->>> " +  this.quote.intermediary_first_name)

    }

    if (this.appuser.role == 'client'){
      this.selectedCustomer = {
        CLIENT_CODE: this.appuser.client_id,
        CLIENT_FIRST_NAME: this.appuser.first_name,
        CLIENT_FAMILY_NAME: this.appuser.last_name,
        CLIENT_HOME_EMAIL: this.appuser.email,
        DISPLAY_SHORT_NAME: `${this.appuser.first_name} ${this.appuser.last_name}`,
        CLIENT_HOME_MOBILE: this.appuser.phone

      };


    }else if (this.appuser.role == 'staff'){
      this.quote.module = 'staff'
      this.quote.staff.staff_id = this.appuser.code,
      this.quote.staff.staff_email = this.appuser.email

    }else{}




    if (this.appuser.code == '30741') {
      this.isStaff = true
    }





    this.selectedMethod = 'Mobile';

    //this.onDaysChanged(parseInt(this.quote.quote_days))
    this.showSchedulesAndRisks();
    this.showCurrencies();
    this.showBodyTypes();

    //Load car Year
    for (i = (new Date()).getFullYear(); i > 1950; i--) {
      this.yearData.push({ value: i.toString(), viewValue: i.toString() });
    }

    //Load makes
    this.onLoadMake();
    //on Make Filter
    this.onFilterMake();

    //on Model Filter
    this.onFilterModel();

    //on Model Filter
    this.onFilterColor();


  }


  populateQuoteValues() {

    this.quote.inception_date = this.service.strDateToDBFormat(this.quote.inception_date)
    this.quote.expiry_date = this.service.strDateToDBFormat(this.quote.expiry_date)
    this.daysTextFieldInput = this.quote.quote_days.toString()
    this.quote.customer_date_of_birth = this.service.strDateToDBFormat(this.quote.customer_date_of_birth)
    this.quote.product = this.quote.product.toString()
    this.quote.duration_type = this.quote.duration_type.toString()

    //applicable excess
    if (this.quote.quote_objects[this.objecth_position].excess_type_code === 'EIB') {
      this.check_excess_buy_back = true
    } else {
      this.check_excess_buy_back = false
    }

    if (this.quote.quote_objects[this.objecth_position].tppd_limit > 0) {
      this.check_extra_tppd_limit = true
    } else {
      this.check_extra_tppd_limit = false
    }

    if (this.quote.quote_objects[this.objecth_position].additional_pa_amt > 0) {
      this.check_additional_pa = true
    } else {
      this.check_additional_pa = false
    }

    if (this.quote.quote_objects[this.objecth_position].additional_loading_amt > 0) {
      this.check_additional_loading = true
    } else {
      this.check_additional_loading = false
    }




  }


  //input change
  cubic_capacity_change(event) {
    // for (let i = 0; i < this.scheduleData.length; i++)

    //   this.scheduleData[i].HIDE = false;
    // if (!isNaN(+`${event.target.value}`) && parseFloat(event.target.value) > 3000) {
    //   for (let i = 0; i < this.scheduleData.length; i++) {
    //     if (this.scheduleData[i].TABLED_DESIGNATION == 'OWN GOODS CARRYING Z.300 (UPTO - 3000 CC)'){
    //       //this.scheduleData[i].HIDE = true;

    //       this.service.swalAlart.fire({
    //         title: 'Alert',
    //         html: 'Sorry, value for CC must NOT be above 3000 for the selected schedule type (OWN GOODS CARRYING Z.300 (UPTO - 3000 CC))',
    //         icon: 'warning',
    //         confirmButtonText:'Ok',
    //         showConfirmButton: true,
    //         allowOutsideClick: false,
    //         allowEscapeKey: false
    //       }).then((result)=>{
    //         if(result.value)
    //         this.quote.quote_objects[this.objecth_position].cubic_capacity = ""
    //        });

    //       }
    //   }
    // }



    //OWN GOODS CARRYING Z.300 (UPTO - 3000 CC)
    if (this.quote.quote_objects[this.objecth_position].risk == '14') {
      if (parseFloat(event.target.value) > 3000) {
        this.service.swalAlart.fire({
          title: 'Alert',
          html: 'Sorry, value for CC must NOT be above 3000 for the selected schedule type (OWN GOODS CARRYING Z.300 (UPTO - 3000 CC))',
          icon: 'warning',
          confirmButtonText: 'Ok',
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((result) => {
          if (result.value)
            this.quote.quote_objects[this.objecth_position].cubic_capacity = ""
        });
      }

      //GENERAL CARTAGE Z.301 (UPTO - 3000 CC)
    } else if (this.quote.quote_objects[this.objecth_position].risk == '8') {
      if (parseFloat(event.target.value) > 3000) {
        this.service.swalAlart.fire({
          title: 'Alert',
          html: 'Sorry, value for CC must NOT be above 3000 for the selected schedule type (GENERAL CARTAGE Z.301 (UPTO - 3000 CC))',
          icon: 'warning',
          confirmButtonText: 'Ok',
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((result) => {
          if (result.value)
            this.quote.quote_objects[this.objecth_position].cubic_capacity = ""
        });
      }
    }

  }


  cubic_capacity_focusout(event) {
    //OWN GOODS CARRYING Z.300 (ABOVE - 3000 CC)
    if (this.quote.quote_objects[this.objecth_position].risk == '15') {
      if (parseFloat(event.target.value) <= 3000) {
        this.service.swalAlart.fire({
          title: 'Alert',
          html: 'Sorry, value for CC must be above 3000 for the selected schedule type (OWN GOODS CARRYING Z.300 (ABOVE - 3000 CC))',
          icon: 'warning',
          confirmButtonText: 'Ok',
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((result) => {
          if (result.value)
            this.quote.quote_objects[this.objecth_position].cubic_capacity = ""
        });
      }

      //GENERAL CARTAGE Z.301 (ABOVE - 3000 CC)
    } else if (this.quote.quote_objects[this.objecth_position].risk == '9') {
      if (parseFloat(event.target.value) <= 3000) {
        this.service.swalAlart.fire({
          title: 'Alert',
          html: 'Sorry, value for CC must be above 3000 for the selected schedule type (GENERAL CARTAGE Z.301 (ABOVE - 3000 CC))',
          icon: 'warning',
          confirmButtonText: 'Ok',
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((result) => {
          if (result.value)
            this.quote.quote_objects[this.objecth_position].cubic_capacity = ""
        });
      }
    }

  }

  // get the selected editable quote
  populateEditableQuote(selectQuote) {
    if (selectQuote) {
      this.quote = {
        ...this.quote,
        edit_mode: true,
      };
      this.onCoverSelected(selectQuote.PRODUCT_CODE.toString());


      let expireDateDiff: number = moment(moment(this.service.strDateToDBFormat(selectQuote.EXPIRY_DATE)).format('YYYY-MM-DD')).diff(moment().format('YYYY-MM-DD'), 'days');

      //console.log('expireDateDiff',{expireDateDiff})
      if (expireDateDiff >= 0)
        this.quote = { ...this.quote, start_date: moment(this.service.strDateToDBFormat(selectQuote.EXPIRY_DATE)).add(1, 'days').format('YYYY-MM-DD') };
      else if (expireDateDiff < 0)
        this.quote = { ...this.quote, start_date: new Date() };


      this.quote = {
        ...this.quote,
        client_code: selectQuote.CLIENT_CODE,
        customer_first_name: (selectQuote.CUSTOMER_FIRST_NAME ? selectQuote.CUSTOMER_FIRST_NAME : selectQuote.CUSTOMER_LAST_NAME),
        customer_last_name: selectQuote.CUSTOMER_LAST_NAME,
        customer_email: selectQuote.CUSTOMER_EMAIL ? selectQuote.CUSTOMER_EMAIL : '',
        customer_phone: selectQuote.CUSTOMER_PHONE,
        prepared_by: selectQuote.PREPARED_BY,
        customer_date_of_birth: this.service.strDateToDBFormat(selectQuote.CUSTOMER_DATE_OF_BIRTH),
        policy_no: selectQuote.POLICY_SEQUENCE,
        legacy_policy_number: this.renewal ? selectQuote.POLICY_SEQUENCE : "",
        vehicle_registration: selectQuote.VEHICLE_REGISTRATION,
        make: selectQuote.MAKE,
        make_code: selectQuote.MAKE_CODE,
        model: selectQuote.QUOTE_MODEL,
        model_code: selectQuote.MODEL_CODE,
        color: selectQuote.COLOR,
        color_code: selectQuote.COLOR_CODE,
        chasis_number: selectQuote.CHASIS_NUMBER,
        mileage: selectQuote.MILEAGE ? selectQuote.MILEAGE : '',
        year_of_manufacture: selectQuote.YEAR_OF_MANUFACTURE.toString(),
        body_type_description: selectQuote.BODY_TYPE_DESCRIPTION,
        body_type_code: this.service.strPadLeft(selectQuote.BODY_TYPE_CODE, 3),
        seats: selectQuote.SEATS,
        currency_code: selectQuote.CURRENCY_CODE,
        cubic_capacity: selectQuote.CUBIC_CAPACITY,
        exchange_rate: selectQuote.EXCHANGE_RATE,
        days_label: (selectQuote.DURATION_TYPE.toLowerCase() == 'short term' || selectQuote.DURATION_TYPE.toLowerCase() == 'short rate' || selectQuote.DURATION_TYPE.toLowerCase() == 'shortrate') ? 'Month(s)' : 'Day(s)',
        sum_insured: parseFloat(selectQuote.SUM_INSURED.replace(",", "").trim()),
        excess_buy_back: selectQuote.EXCESS_TYPE_CODE == 'EIB' ? 1 : 0,
        tppd_value: parseFloat(selectQuote.TPPD_LIMIT),
        tppd: parseFloat(selectQuote.TPPD_LIMIT) ? true : false,
        pa_value: selectQuote.EXTRA_PA,
        pa: parseFloat(selectQuote.EXTRA_PA) ? true : false,
        product: selectQuote.PRODUCT_CODE.toString(),
        schedule_code: selectQuote.RISK_USAGES.toString(),
        vehicle_age_loading: selectQuote.AGE_LOADING_ADDED == '1',
        cc_loading: selectQuote.CC_LOADING_ADDED == '1',
        ncd_value: selectQuote.NCD_DISCOUNT_YEAR.toString().trim(),
        ncd: parseFloat(selectQuote.NCD_DISCOUNT_YEAR) > 0 ? true : false,
        end_date: this.renewal ? '' : this.service.strDateToDBFormat(selectQuote.EXPIRY_DATE),
        quote_days: (selectQuote.DURATION_TYPE.toLowerCase() == 'short term' || selectQuote.DURATION_TYPE.toLowerCase() == 'shortrate' || selectQuote.DURATION_TYPE.toLowerCase() == 'short rate') ? selectQuote.MONTHS : selectQuote.QUOTE_DAYS,
        duration_type: selectQuote.DURATION_TYPE == 'annual' ? 'annual' : (selectQuote.DURATION_TYPE == 'shortrate' ? 'shortrate' : 'prorata'),
        //term:selectQuote.DURATION_TYPE=='annual'?'01':'02',
        start_date: this.service.strDateToDBFormat(selectQuote.INCEPTION_DATE),

        customer_type_code: selectQuote.CUSTOMER_TYPE_CODE
      }

      //console.log('Editing Quote',selectQuote)

      if (this.renewal)
        this.onStartDateChanged();

      //pass legacy values when it is renewal
      if (this.renewal) {
        this.quote = {
          ...this.quote,
          legacy_policy_sequence: selectQuote.LEGACY_POLICY_SEQUENCE,
          legacy_total_premium: selectQuote.COST_OF_INSURANCE.toString().replace(",", '').trim(),
          legacy_tppd_limit: selectQuote.TPPD_LIMIT.toString().replace(",", '').trim(),
          legacy_excess_type_code: selectQuote.EXCESS_TYPE_CODE.toString().replace(",", '').trim(),
          legacy_cc_added: selectQuote.CC_LOADING_ADDED.toString().replace(",", '').trim(),
          legacy_age_added: selectQuote.AGE_LOADING_ADDED.toString().replace(",", '').trim(),
          legacy_additional_pa_amt: selectQuote.AGE_LOADING_ADDED.toString().replace(",", '').trim(),
          inputted_discount: 0,
          inputted_loading: 0
        }
      }

      this.searchMakeCtrl.setValue(this.quote.make);

      //autoload all models
      setTimeout(() => {
        this.onLoadModel()
      }, 1)


      this.searchModelCtrl.setValue(this.quote.model);
      this.searchColorCtrl.setValue(this.quote.color);


      //excess controls
      if (this.quote.tppd_value && this.quote.tppd_value > 0)
        this.quote.can_tppd_limit = this.quote.check_tppd_limit = true;
      //else
      //this.quote.can_tppd_limit=false;



      if (this.quote.excess_buy_back > 0)
        this.quote.can_excess_buy = this.quote.check_excess_buy = true;
      else if (this.quote.product == '203')
        this.quote.can_excess_buy = false;


      if (this.quote.pa_value > 0)
        this.quote.can_pa = this.quote.check_pa = true;
      //else
      //this.quote.can_pa=false;


      if (this.quote.ncd_value > 0) {
        this.quote.can_ncd = this.quote.check_ncd = true;
      }
      else if (this.quote.product == '203')
        this.quote.can_ncd = false;


      if (this.quote.vehicle_age_loading) {
        this.quote.can_vehicle_age_loading = this.quote.check_vehicle_age_loading = true;
      }
      else if (this.quote.product == '203')
        this.quote.can_vehicle_age_loading = false;

      if (this.quote.cc_loading) {
        this.quote.can_cc_loading = this.quote.check_cc_loading = true;
      }
      else if (this.quote.product == '203')
        this.quote.can_cc_loading = false;


      // vehicle make
      this.selectedMake.DISPLAY_SHORT_NAME = this.selectedMake.VEHICLE_MAKE_DESC = selectQuote.MAKE;
      this.selectedModel.VEHICLE_MODEL_MAKE_CODE = this.selectedMake.VEHICLE_MAKE_CODE = selectQuote.MAKE_CODE;

      // vehicle model
      this.selectedModel.DISPLAY_SHORT_NAME = this.selectedModel.VEHICLE_MODEL_DESC = selectQuote.QUOTE_MODEL;
      this.selectedModel.VEHICLE_MODEL_CODE = selectQuote.MODEL_CODE;

      //get seleceted color
      this.selectedColor.DISPLAY_SHORT_NAME = this.selectedColor.TABLED_NAME = selectQuote.COLOR ? selectQuote.COLOR.toLowerCase() : null;
      this.selectedColor.TABLED_CODE = selectQuote.COLOR_CODE;

      //client code
      this.payload = { ...this.payload, client_code: selectQuote.CLIENT_CODE }

    }
  }


  onCoverSelected(code: string) {
    //console.log("coverType=======" + code);
    this.quote.product = code;

    if (this.quote.product === '201') {
      this.quote.product_description = "MOTOR COMPREHENSIVE"
    } else if (this.quote.product === '202') {
      this.quote.product_description = "THIRD PARTY FIRE & THEFT"
    } else if (this.quote.product === '203') {
      this.quote.product_description = "MOTOR THIRD PARTY ONLY"
    }

    //toggle applicable accesses
    this.quote.can_cc_loading = this.quote.can_vehicle_age_loading = this.quote.can_fd = this.quote.can_ncd = this.quote.can_pa = this.quote.can_tppd_limit = this.quote.can_excess_buy = true;
    this.quote.check_cc_loading = this.quote.check_vehicle_age_loading = this.quote.check_fd = this.quote.check_ncd = this.quote.check_pa = this.quote.check_tppd_limit = this.quote.check_excess_buy = false;


    if (this.quote.product === '203') {
      this.check_excess_buy_back = false
      this.check_vehicle_age_loading = false
      this.check_cc_loading = false

      this.quote.sum_insured = "0";

    } else
      this.quote.sum_insured = "";


    //hide prorata for thirdparty
    for (let i = 0; i < this.durationData.length; i++) {
      if (this.quote.product == '203' && this.durationData[i].value == '03')
        this.durationData[i].hide = true;
      else
        this.durationData[i].hide = false;
    }


    if (!this.quote.edit_mode)
      this.validateForm();
  }

  
  onScheduleSelected(code: string) {
    this.quote.quote_objects[this.objecth_position].risk = code;
    //this.quote.quote_objects.risk = code;
    console.log("risk>>>>>" + this.quote.quote_objects[this.objecth_position].risk)

    this.validateForm();
  }

  //on term selection
  onDurationSelected() {
    console.log("onDurationSelected>>>>true")

    //Get the associated days to the term
    let durations = this.durationData.filter((duration: duration) => duration.value == this.quote.duration_type);

    if (durations.length > 0)
      this.quote.quote_days = durations[0].days;

    //recalculate the end date
    if (!isNaN(+`${this.quote.quote_days}`) && this.quote.inception_date)
      this.quote.expiry_date = moment(this.quote.inception_date).subtract((this.quote.duration_type == 'shortrate' ? 1 : 0), 'days').add(parseInt(this.quote.quote_days) >= 364 ? 364 : parseInt(this.quote.quote_days), (this.quote.duration_type == 'shortrate' ? 'months' : 'days')).format('YYYY-MM-DD');


    //change the days label to months when the term changes to short term
    if (this.quote.duration_type == 'shortrate') {

      this.quote.days_label = 'Days';
      let days = this.quote.quote_days = Math.abs(moment(moment(this.quote.inception_date).format('YYYY-MM-DD')).diff(moment(this.quote.expiry_date).format('YYYY-MM-DD'), 'days'));
      this.quote._days = days ? days : 1;
      this.quote._months = '';

      this.daysTextFieldInput = parseInt(this.quote.quote_days) + 2;
      this.annual = false;

      this.quote.quote_days = this.daysTextFieldInput
      

    } else if (this.quote.duration_type == 'annual' && this.quote.quote_days != '' && parseInt(this.quote.quote_days) >= 364) {

      this.quote.quote_days = 365;
      this.daysTextFieldInput = parseInt(this.quote.quote_days);
      this.annual = true;


      //increase expiry date by 1 day and reduce by 1
      this.quote.expiry_date = moment(this.quote.inception_date).add(this.quote.quote_days, 'days').format('YYYY-MM-DD');



    } else {
      this.quote.days_label = 'Days';
      let days = this.quote.quote_days = Math.abs(moment(moment(this.quote.inception_date).format('YYYY-MM-DD')).diff(moment(this.quote.expiry_date).format('YYYY-MM-DD'), 'days'));
      this.quote._days = days ? days : 1;
      this.quote._months = '';

    }




    //hide thirdparty for prorata
    for (let i = 0; i < this.coverData.length; i++) {
      if (this.quote.duration_type == 'duration_type' && this.coverData[i].value == '203')
        this.coverData[i].hide = true;
      else
        this.coverData[i].hide = false;
    }



  }



  onBodySelected(code: string) {
    // console.log("body=======" + code);
    this.quote.quote_objects[this.objecth_position].body_type_code = code;
    this.validateForm();
  }

  onYearSelected(code: string) {
    this.quote.quote_objects[this.objecth_position].year_of_manufacture = code;
    this.validateForm();
  }

  onCurrencySelected(code: string) {
    let selectedCurrency = this.currencyData.filter((currency: Currency) => currency.CURRENCY == code);

    if (selectedCurrency.length) {
      this.quote.exchange_rate = selectedCurrency[0].CURRENCY_VALUE.toString();
      this.quote.currency_code = code;
    }

    this.validateForm();
  }

  onNCDSelected(code: string) {
    this.quote.ncd_value = code;
  }



  onStartDateChanged() {
    console.log("onStartDateChanged")

    let inception_date = this.quote.inception_date ? moment(this.quote.inception_date).format('YYYY-MM-DD') : null;

    let days = parseInt(this.quote.quote_days);

    if (days >= 365)
      days = 364;

    let expiry_date = moment(inception_date).add(!isNaN(+`${days}`) ? days : 1, 'days').format('YYYY-MM-DD');
    this.quote.expiry_date = expiry_date;

    if (this.quote.inception_date && this.quote.expiry_date) {
      if (this.quote.duration_type == 'annual') {
        this.quote.quote_days = '365';
        this.daysTextFieldInput = '365';

        //increase expiry date by 1 day and reduce by 1
        this.quote.expiry_date = moment(this.quote.inception_date).add(this.quote.quote_days, 'days').format('YYYY-MM-DD');

      } else {
        this.quote.quote_days = this.quote._days = moment(expiry_date).diff(inception_date, 'days').toString();
        this.daysTextFieldInput = parseInt(this.quote.quote_days) + 2;
        this.quote.quote_days = this.daysTextFieldInput

        //  if(this.quote.term=='02')
        //  this.quote.days=this.quote._months=Math.round(moment(expiry_date).diff(inception_date,'days')/31);

      }
    }



    // //increase expiry date by 1 day
    // this.quote.expiry_date =  moment(inception_date).add(days + 1, 'days').format('YYYY-MM-DD');


    this.validateForm();

  }



  onEndDateChanged(date: Date) {
    let inception_date = this.quote.inception_date ? moment(this.quote.inception_date).format('YYYY-MM-DD') : null;

    let days = 0, months = 0;
    if (inception_date) {
      if (this.quote.duration_type == 'shortrate') {
        days = moment(moment(date).format('YYYY-MM-DD')).diff(inception_date, 'days');
        this.quote.quote_days = days.toString();

        this.daysTextFieldInput = parseInt(this.quote.quote_days) + 2;
        this.quote.quote_days = this.daysTextFieldInput

        // months=Math.round(moment(moment(date).format('YYYY-MM-DD')).add(1,'days').diff(inception_date,'days')/31);
        // this.quote.days=this.quote._months=this.quote._months=months;

        // if(this.quote.term=='02' && months==0){
        //  this.quote.term='03';
        //  this.quote.days_label="Days"
        // }
      }
      if (this.quote.duration_type != 'shortrate') {
        days = moment(moment(date).format('YYYY-MM-DD')).diff(inception_date, 'days');
        this.quote.quote_days = days.toString();

        this.daysTextFieldInput = parseInt(this.quote.quote_days);

      }
    }

    if (days && days < 365 && this.quote.duration_type == 'annual') {
      months = moment(moment(date).format('YYYY-MM-DD')).diff(inception_date, 'months');

      if (months) {
        this.quote.days_label = "Days";
        this.quote.duration_type = 'shortrate';


        //this.quote.days_label="Months";
        //this.quote.term='02';
        //this.quote.days=months;

      }
      else {
        this.quote.days_label = "Days";
        this.quote.duration_type = 'prorata';
      }
    }
    else if (days == 365) {
      this.quote.duration_type = 'annual';
    }



    this.validateForm();




  }



  //Start Date dateFilters
  startDateFilter = (date: Date) => {
    return moment(moment(date).format('YYYY-MM-DD')).diff(moment().format('YYYY-MM-DD'), 'days') >= 0;
  }
  //End Date dateFilters
  endDateFilter = (date: Date) => {
    let diff = moment(moment(date).format('YYYY-MM-DD')).diff(moment(this.quote.inception_date).format('YYYY-MM-DD'), 'days');
    return diff > 0 && diff <= 364;
  }

  /* handle access changes===*/
  onExcessBuyBackChange(checked: boolean) {
    this.check_excess_buy_back = checked;
    if (checked) {
      this.quote.quote_objects[this.objecth_position].excess_type_code = 'EIB'
    } else {
      this.quote.quote_objects[this.objecth_position].excess_type_code = 'ENA'
    }


  }
  onTppdChange(checked: boolean) {
    if (!checked)
      this.quote.quote_objects[this.objecth_position].tppd_limit = 0;
    this.check_extra_tppd_limit = checked;

  }
  onPaChange(checked: boolean) {
    if (!checked)
      this.quote.quote_objects[this.objecth_position].additional_pa_amt = 0;
    this.check_additional_pa = checked;

  }
  onNcdChange(checked: boolean) {
    if (!checked)
      this.quote.ncd_value = 0;
    this.quote.ncd = checked;

  }
  onFdChange(checked: boolean) {
    this.quote.fd = checked;
  }
  onVehicleAgeLoadingChange(checked: boolean) {
    this.check_vehicle_age_loading = checked;
  }
  onCCLoadingChange(checked: boolean) {
    this.check_cc_loading = checked;
  }
  onDiscountChange(checked: boolean) {
    if (!checked)
      this.quote.inputted_discount = 0;

    this.quote.additional_discount = checked;
  }
  onLoadingDiscountChange(checked: boolean) {
    if (!checked)
      this.quote.quote_objects[this.objecth_position].additional_loading_amt = 0;
    this.check_additional_loading = checked;
  }


  onRenewalChange() {

    if (this.renewal_checked == 'renew')
      this.service.swalAlart.fire({
        text: "Premium computations for this policy will be treated as renewal.\nDo you wish to proceed?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: `Yes,Proceed`,
        reverseButtons: true
      })
        .then((dialogResponse) => {
          if (dialogResponse.hasOwnProperty('value') && dialogResponse.value == true) {
            this.renewal = true
            this.quote.edit_mode = true;
            this.quote.product = this.selectedQuote.PRODUCT_CODE
            this.quote.schedule_code = this.selectedQuote.RISK_USAGES
            this.quote.duration_type = this.selectedQuote.DURATION_TYPE == 'annual' ? 'annual' : (this.selectedQuote.DURATION_TYPE == 'shortrate' ? 'shortrate' : 'prorata')
            this.quote.quote_days = this.selectedQuote.QUOTE_DAYS
          }
        })
    else
      this.service.swalAlart.fire({
        text: "Premium computations for this policy will be treated as a new business.\nDo you wish to proceed?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: `Yes,Proceed`,
        reverseButtons: true
      })
        .then((dialogResponse) => {
          if (dialogResponse.hasOwnProperty('value') && dialogResponse.value == true) {
            this.quote.edit_mode = false;
            this.renewal = false;
          }
        })
  }

  //open search customer dialog
  /* openSearchCustomerDialog(){
    const dialogRef = this.dialog.open(SearchMotoQuoteCustomerComponent, {
      width: '600px',
      data: { selectedCustomer: this.selectedCustomer,isNewCustomer: this.isNewCustomer }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.hasOwnProperty('data')){
        this.selectedCustomer = result.data;

        if(this.selectedCustomer.isNewCustomer)
           this.openCustomerCreationDialog();
        else{
          if(this.selectedCustomer){

            this.quote.customer_code=this.selectedCustomer.CLIENT_CODE;
            //this.payload.customer_code=this.quote.customer_code;

            this.quote.customer_first_name=this.selectedCustomer.CLIENT_FIRST_NAME;
            this.quote.customer_last_name=this.selectedCustomer.CLIENT_FAMILY_NAME;
            this.quote.customer_email=this.selectedCustomer.CLIENT_HOME_EMAIL;
            this.quote.customer_phone=this.selectedCustomer.CLIENT_HOME_MOBILE;
            this.quote.customer_phone=this.selectedCustomer.CLIENT_HOME_MOBILE;

            this.quote.date_of_birth=this.selectedCustomer.CLIENT_DATE_OF_BIRTH;
            this.validateForm();
          }
       }

      }

    });
  }
 */


  //choose payment method
  choosePaymentMethod() {
    const dialogRef = this.dialog.open(SelectPaymentMethodComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: this.payload, method: null, total_premium: this.quote.total_premium }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.confirmedQuote;
        this.selectedMethod = result.data.method;

        if (this.selectedMethod)
          this.confirmPin();

        this.validateForm();
      }
    });
  }

  //confirm login pin to continue
  confirmPin() {
    let currentUser = this.service.currentUser();
    if (currentUser.role == "broker-user") {
      this.loginAsBrokerUser()
    } else {
      this.loginAsAgentClient()
    }
  }

  loginAsAgentClient() {
    this.service.swalAlart.fire({
      //title: "Identity Confirmation",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      inputPlaceholder: "Confirm your Pin",
      input: "password",
      reverseButtons: true
    })
      .then((result: SweetAlertResult) => {
        if (result.value) {
          let currentUser = this.service.currentUser();
          //prepare payload
          let loginPayload = {};
          if (currentUser.role == "client") {
            loginPayload = {
              phone: currentUser.phone,
              pin: result.value,
              module: 'client'
            }

          } else if (currentUser.role == "agent") {
            loginPayload = {
              agent_code: currentUser.code,
              pin: result.value,
              module: 'agent'
            }

          } else { }

          swal.showLoading()
          this.service.webLogin(loginPayload)
            .toPromise()
            .then((responseData: any) => {
              swal.hideLoading()
              if (responseData.hasOwnProperty('responseCode') && responseData.responseCode === '000') {
                if (this.selectedMethod == 'Mobile')
                  this.openMomoPaymentDialog();
                else if (this.selectedMethod == 'Cash')
                  this.openCashPaymentDialog();
                else if (this.selectedMethod == 'Cheque')
                  this.openChequePaymentDialog();
                else if (this.selectedMethod == 'Card')
                  this.openCardPaymentDialog();
              }
              else {
                swal.fire({
                  title: 'Wrong credentials!',
                  text: 'Please check and try again',
                  icon: 'error',
                });
                setTimeout(() => { this.confirmPin() }, 2000);
              }

            }).catch(error => {
              swal.fire({
                title: 'Connection Failed!',
                text: 'Login Failed',
                icon: 'error',
              });
              setTimeout(() => { this.confirmPin() }, 2000);
            })
        }
        else
          this.alertSnackBar('No Pin was entered', 'User Not Verified');
      });

  }


  loginAsBrokerUser() {
    this.service.swalAlart.fire({
      //title: "Identity Confirmation",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      inputPlaceholder: "Confirm your Pin",
      input: "password",
      reverseButtons: true
    })
      .then((result: SweetAlertResult) => {
        if (result.value) {
          let currentUser = this.service.currentUser();
          //prepare payload
          let loginPayload = {};
          loginPayload = {
            broker_user_code: currentUser.code,
            broker_user_pin: result.value,
            module: 'broker-user'
          }

          swal.showLoading()
          this.service.webLoginAsBrokerUser(loginPayload)
            .toPromise()
            .then((responseData: any) => {
              swal.hideLoading()
              if (responseData.hasOwnProperty('responseCode') && responseData.responseCode === '000') {
                if (this.selectedMethod == 'Mobile')
                  this.openMomoPaymentDialog();
                else if (this.selectedMethod == 'Cash')
                  this.openCashPaymentDialog();
                else if (this.selectedMethod == 'Cheque')
                  this.openChequePaymentDialog();
                else if (this.selectedMethod == 'Card')
                  this.openCardPaymentDialog();
              }
              else {
                swal.fire({
                  title: 'Wrong credentials!',
                  text: 'Please check and try again',
                  icon: 'error',
                });
                setTimeout(() => { this.confirmPin() }, 2000);
              }

            }).catch(error => {
              swal.fire({
                title: 'Connection Failed!',
                text: 'Login Failed',
                icon: 'error',
              });
              setTimeout(() => { this.confirmPin() }, 2000);
            })
        }
        else
          this.alertSnackBar('No Pin was entered', 'User Not Verified');
      });
  }







  //open momo Payment Dialog
  openMomoPaymentDialog() {
    const dialogRef = this.dialog.open(MomoPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: this.payload, total_premium: this.quote.total_premium, policy_lob: "20" }
    });
  }


  //open card Payment Dialog
  openCardPaymentDialog() {
    const dialogRef = this.dialog.open(DebitCardPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: this.payload, total_premium: this.quote.total_premium, policy_lob: "20" }
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.quotePayload.module == 'PAID';

        if (this.confirmedQuote) {
          setTimeout(() => {
            this.router.navigate(['transactions']);
          }, 3000)
        }
      }

    });
  }

  //open cash Payment Dialog
  openCashPaymentDialog() {
    const dialogRef = this.dialog.open(CashPaymentComponent, {
      disableClose: true,
      width: '350px',
      data: { quotePayload: this.payload, total_premium: this.quote.total_premium, policy_lob: "20" }
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.quotePayload.module == 'PAID';
        this.validateForm();

        if (this.confirmedQuote) {
          setTimeout(() => {
            this.router.navigate(['transactions']);
          }, 3000)
        }
      }

    });
  }
  //open Cheque Payment Dialog
  openChequePaymentDialog() {
    const dialogRef = this.dialog.open(ChequePaymentComponent, {
      disableClose: true,
      width: '600px',
      data: { quotePayload: this.payload, total_premium: this.quote.total_premium, policy_lob: "20" }
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result.hasOwnProperty('data')) {
    //     this.confirmedQuote = result.data.quotePayload.module == 'PAID';
    //     this.validateForm();

    //     if (this.confirmedQuote) {
    //       setTimeout(() => {
    //         this.router.navigate(['transactions']);
    //       }, 3000)
    //     }
    //   }
    // });

    
  }

  //confirm quote
  confirmQuote() {
    let selectedRisk: any = this.scheduleData.filter((risk: scheduleType) => risk.TABLED_CODE == this.payload.risk);

    if (selectedRisk)
      selectedRisk = selectedRisk[0];

    let summary = {
      CLIENT_CODE: this.payload.client_code,
      INCEPTION_DATE: moment(this.quote.start_date).format('DD/MM/YYYY'),
      EXPIRY_DATE: moment(this.quote.end_date).format('DD/MM/YYYY'),
      DATE_COMPLETED: moment().format('DD/MM/YYYY'),
      PREPARED_BY: this.payload.prepared_by,
      CUSTOMER_FIRST_NAME: this.payload.customer_first_name,
      CUSTOMER_LAST_NAME: this.payload.customer_last_name,
      CUSTOMER_EMAIL: this.payload.customer_email,
      CUSTOMER_PHONE: this.payload.customer_phone,
      YEAR_OF_MANUFACTURE: this.payload.year_of_manufacture,
      VEHICLE_REGISTRATION: this.payload.vehicle_registration,
      MAKE: this.payload.make,
      QUOTE_MODEL: this.payload.model,
      CUBIC_CAPACITY: this.payload.cubic_capacity,
      SUM_INSURED: this.payload.sum_insured,
      QUOTE_DAYS: (this.payload.duration_type == 'shortrate' ? this.payload.quote_days : this.payload.quote_days),
      DURATION_TYPE: this.payload.duration_type,
      EXCESS_TYPE_CODE: this.payload.excess_type_code,
      CURRENCY_CODE: this.payload.currency_code,
      RISK_DESCRIPTION: selectedRisk ? selectedRisk.TABLED_DESIGNATION : null,
      COST_OF_INSURANCE: this.quote.total_premium,
      TPPD_LIMIT: this.quote.tppd_value,
      MONTHS: this.payload.months ? this.payload.months : 1,
      PRODUCT_CODE: this.payload.product,
      INPUTTED_DISCOUNT: this.payload.inputted_discount,
      EXTRA_PA: this.quote.pa_value
    }

    const dialogRef = this.dialog.open(QuoteSummaryComponent, {
      disableClose: true,
      width: '500px',
      data: {
        flow: 'moto',
        hide_foreign_currency: 0,
        quote: summary,
        total_premium: this.quote.total_premium,
        foreign_currency: this.quote.foreign_currency,
        foreign_amount: this.quote.foreign_amount,
        confirmedQuote: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.hasOwnProperty('data')) {
        this.confirmedQuote = result.data.confirmedQuote;
        this.choosePaymentMethod();
        this.validateForm();
      }
    });
  }

  onDaysChanged(days: number) {
    //days=!isNaN(+`${this.quote.days}`)?parseInt(this.quote.days):1;

    console.log("onDaysChanged>>>" + days)

    if (days >= 365) {
      days = 364;
      this.quote.quote_days = "365";
      this.quote.duration_type = "annual";

      this.daysTextFieldInput = "365";
      this.annual = true;

      console.log('Days changed is annual', days);

    } else {
      this.quote.duration_type = "shortrate";

      if (localStorage.getItem("motor-quote")) {
        this.daysTextFieldInput = days + 0
        this.annual = false

        this.quote.quote_days = this.daysTextFieldInput
      }


      console.log('Days changed is shortrate', days);
    }


    //restrict negative days
    if (days <= 0)
      this.quote.quote_days = '1';



    if (this.quote.inception_date) {
      let inception_date = this.quote.inception_date ? moment(this.quote.inception_date) : null;
      let expiry_date = moment(inception_date).add(days - 1, 'days').format('YYYY-MM-DD'); //minus 1 to days
      this.quote.expiry_date = expiry_date;
    }




  }



  /*=== //handle Access changes==*/
  transformAmount(element) {
    this.formattedAmount = this.currencyPipe.transform(this.formattedAmount, 'GHS ');
    element.target.value = this.formattedAmount;
  }

  onDaysInput(event) {

    let days = !isNaN(+`${event.target.value}`) ? parseInt(event.target.value) : 0;

    if (!days) {
      event.target.value = 1;
    }
    else if (days > 365)
      event.target.value = 365;

  }


  onBackClicked() {
    this.router.navigate(["/fleet-motor-quote"])
  }

  //http requests
  showSchedulesAndRisks() {
    this.service.getSchedulesAndRisks()
      .toPromise()
      .then((response: scheduleTypeResponse) => {
        if (response.responseCode == '000') {
          response.profile.map((schedule: any) => {
            this.scheduleData.push({ ...schedule, HIDE: false });
          })
        }
      }).catch(err => {
        //console.log('Schedules load error',err);
      });
  }

  //open customer creation
  openCustomerCreationDialog() {
    const dialogRef = this.dialog.open(CreateCustomerComponent, {
      disableClose: true,
      width: '800px',
      data: { newCustomerDetails: {} }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result.hasOwnProperty('data') && result.data.hasOwnProperty('client_code'))
        this.quote = {
          ...this.quote,
          client_code: result.data.client_code,
          type: result.data.type,
          customer_first_name: result.data.customer_first_name,
          customer_other_names: result.data.customer_other_names,
          customer_last_name: result.data.customer_last_name,
          customer_date_of_birth: moment(result.data.customer_date_of_birth).format('YYYY-MM-DD'),
          customer_email: result.data.customer_email ? result.data.customer_email : '',
          customer_phone: result.data.customer_phone,
          digital_address: result.data.digital_address,
          id_type: result.data.id_type,
          id_number: result.data.id_number,
          tin: result.data.tin
        }

      if (this.quote.type == '0')
        this.selectedCustomer.DISPLAY_SHORT_NAME = `${this.quote.customer_first_name},${this.quote.customer_last_name},${this.quote.customer_phone}`;
      else
        this.selectedCustomer.DISPLAY_SHORT_NAME = `${this.quote.customer_first_name},${this.quote.customer_phone}`;


    });
  }
  //http requests
  showCurrencies() {
    this.service.getCurrencies()
      .toPromise()
      .then((response: CurrencyResponse) => {
        if (response.responseCode == '000') {
          response.responseData.map((currency: Currency) => {
            this.currencyData.push(currency);
          })
        }
      }).catch(err => {
        //console.log('Currencies load error',err);
      });
  }

  showBodyTypes() {
    this.service.getBodyTypes()
      .toPromise()
      .then((response: BodyTypeResponse) => {
        if (response.responseCode == '000') {
          response.responseData.map((body: BodyType) => {
            this.bodyData.push(body);
          })

        }
      }).catch(err => {
        //console.log('Body Type load error',err);
      });
  }

  //run validation
  validateForm(input?: any): boolean {
    let hasError = false;
    this.errors = {
      client_code: false,
      customer_phone: false,
      customer_date_of_birth: false,
      year_of_manufacture: false,
      cubic_capacity: false,
      mileage: false,
      vehicle_registration: false,
      seats: false,
      chasis_number: false,
      body_type_code: false,
      product: false,
      schedule_code: false,
      start_date: false,
      end_date: false,
      make: false,
      model: false,
      color: false,
      currency_code: false,
      sum_insured: false,
      customer_email: false
    }
    if (!this.quote.client_code) {
      hasError = true;
      this.errors = { ...this.errors, client_code: true };
    }
    if (isNaN(+`${this.quote.customer_phone}`) && !this.service.validatePhone(this.quote.customer_phone)) {
      hasError = true;
      this.errors = { ...this.errors, customer_phone: true };
    }
    if (this.quote.currency_code.trim().length == 0) {
      hasError = true;
      this.errors = { ...this.errors, currency_code: true };
    }
    if (this.quote.product != '203' && !this.quote.quote_objects[this.objecth_position].sum_insured) {
      hasError = true;
      this.errors = { ...this.errors, sum_insured: true };
    }
    if (this.quote.customer_email && !this.service.validateEmail(this.quote.customer_email)) {
      hasError = true;
      this.errors = { ...this.errors, customer_email: true };
    }

    if (!(moment(this.quote.customer_date_of_birth).isValid() && moment(moment().format('YYYY-MM-DD')).diff(moment(this.quote.customer_date_of_birth).format('YYYY-MM-DD'), 'years') >= 18)) {
      hasError = true;
      this.errors = { ...this.errors, customer_date_of_birth: true };

      this.alertSnackBar(`Date of birth must be greater or equal to:${moment(this.dobStartAt).format('LL')}`, 'Invalid Date');
    }

    if (!this.quote.quote_objects[this.objecth_position].year_of_manufacture) {
      hasError = true;
      this.errors = { ...this.errors, year_of_manufacture: true };
    }

    if (!this.quote.quote_objects[this.objecth_position].cubic_capacity) {
      hasError = true;
      this.errors = { ...this.errors, cubic_capacity: true };
    }

    /* if(!this.quote.mileage){
       hasError=true;
       this.errors={...this.errors,mileage:true};
     }*/

    if (this.quote.quote_objects[this.objecth_position].vehicle_registration.trim().length == 0) {
      hasError = true;
      this.errors = { ...this.errors, vehicle_registration: true };
    }

    if (!this.quote.quote_objects[this.objecth_position].seats) {
      hasError = true;
      this.errors = { ...this.errors, seats: true };
    }

    if (!this.quote.quote_objects[this.objecth_position].chasis_number || this.quote.quote_objects[this.objecth_position].chasis_number.length > 17) {

      hasError = true;
      this.errors = { ...this.errors, chasis_number: true };
      setTimeout(() => {
        this.alertSnackBar("Vehicle chasis number should be 17 characters or less", "Note:: Chasis number");
      }, 2000)
    }

    if (this.quote.quote_objects[this.objecth_position].body_type_code.trim().length == 0) {
      hasError = true;
      this.errors = { ...this.errors, body_type_code: true };
    }

    if (!this.quote.product.trim()) {
      hasError = true;
      this.errors = { ...this.errors, product: true };
    }

    if (!this.quote.quote_objects[this.objecth_position].risk) {
      hasError = true;
      this.errors = { ...this.errors, schedule_code: true };
    }

    if (!moment(this.quote.inception_date).isValid()) {
      hasError = true;
      this.errors = { ...this.errors, start_date: true };
    }

    if (!moment(this.quote.expiry_date).isValid()) {
      hasError = true;
      this.errors = { ...this.errors, end_date: true };
    }

    if (!this.quote.quote_objects[this.objecth_position].color) {
      hasError = true;
      this.errors = { ...this.errors, color: true };
    }

    if (!this.quote.quote_objects[this.objecth_position].make) {
      hasError = true;
      this.errors = { ...this.errors, make: true };
    }

    if (!this.quote.quote_objects[this.objecth_position].quote_model) {
      hasError = true;
      this.errors = { ...this.errors, model: true };
    }
    return hasError;
  }

  //on Check Chasis Number
  onCheckChasis(event) {
    event.preventDefault();

    if (this.renewal == true)
      this.onSubmit(event);
    else {
      this.service.swalAlart.showLoading()
      this.service.checkChasis({ chasis_number: this.quote.quote_objects[this.objecth_position].chasis_number, status_of_client: this.quote.status_of_client, inception_date: moment(this.quote.start_date).format('DD/MM/YYYY') })
        .toPromise()
        .then((response: any) => {
          this.service.swalAlart.close()
          if (response.responseCode == 'E40') {
            this.service.swalAlart.fire({
              text: "Chassis number already exist under different policy",
              title: 'Chassis Validation',
              icon: 'error'
            })
          }
          else
            this.onSubmit(event);
        });
    }
  }

  //On Form submit
  onSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm())
      this.service.swalAlart.fire({
        title: 'Validation Alert',
        text: 'Kindly complete the highlighted fields',
        icon: 'warning',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      })
    else if (this.quote.duration_type == '03') {
      this.service.swalAlart.fire({
        text: "Prorata Term does not apply to current policy",
        icon: "question",
        //showCancelButton: true,
        confirmButtonText: `Ok`
      })
    }
    else {
      this.service.swalAlart.fire({
        text: "Do you want to add new vehicle to quote?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: `Yes,Proceed`,
        reverseButtons: true
      })
        .then((dialogResponse) => {
          if (dialogResponse.hasOwnProperty('value') && dialogResponse.value == true) {
            //start of processing

            this.processing = true;

            if (this.appuser.role == 'staff'){
              let prepared_by = `${this.appuser.first_name ? this.appuser.first_name : ''}`.trim();
              this.quote.prepared_by = prepared_by
              this.quote.module = this.appuser.role.toLowerCase()
              //this.quote.policy_branch = 1001
            }else{
              let prepared_by = `${this.appuser.first_name ? this.appuser.first_name : ''} ${this.appuser.last_name ? this.appuser.last_name : ''}`.trim();
              this.quote.prepared_by = prepared_by
              this.quote.policy_branch = this.appuser.agency
              this.quote.module = this.appuser.role.toLowerCase()
              this.quote.staff = null
  
            }

            
            this.quote.fleet = true
            this.quote.status_of_client = (this.renewal_checked == 'renew' ? 'renew' : 'new'),
            this.quote.customer_date_of_birth = moment(this.quote.customer_date_of_birth).format('DD/MM/YYYY'),
            this.quote.inception_date = moment(this.quote.inception_date).format('DD/MM/YYYY'),
            this.quote.expiry_date = moment(this.quote.expiry_date).format('DD/MM/YYYY'),
            this.quote.quote_objects[this.objecth_position].inception_date = this.quote.inception_date
            this.quote.quote_objects[this.objecth_position].expiry_date = this.quote.expiry_date
            this.quote.client_code = this.quote.client_code,
            this.quote.policy_lob = "20",
            this.quote.platform = "Webapp"
            this.quote.quote_objects[this.objecth_position].object_days = this.quote.quote_days


            if (this.appuser.role == 'agent') {
              this.quote = {
                ...this.quote,
                agent_code: this.appuser.code.toString(),
                client_code: this.quote.client_code.toString(),
                broker_user_code: '',
              }
            }
            else if (this.appuser.role == 'client') {
              this.quote = {
                ...this.quote,
                agent_code: '',
                client_code: this.appuser.code.toString(),
                broker_user_code: '',
              }
            } else if (this.appuser.role == 'broker-user') {
              this.quote = {
                ...this.quote,
                agent_code: this.appuser.broker_user_main_broker_code.toString(),
                client_code: this.quote.client_code.toString(),
                broker_user_code: this.appuser.code.toString(),
              }
            }


            console.log("motor-quote===> " + JSON.stringify(this.quote))


            //connect to api
            console.log("quote_activity>>>>>>>>" + localStorage.getItem("quote_activity"))

            if (this.quote_activity == 'new_add') {
              this.createNewQuote(this.quote)

            } else if (this.quote_activity == 'edit_add') {
              this.addVehicle(this.quote)

            } else if (this.quote_activity == 'edit') {
              this.editVehicle(this.quote)

            } else if (this.quote_activity == 'policy_renewals_edit_add') {
              this.quote.date_created = ""
              this.quote.updated = ""
              this.quote.status_of_client = "renew"

              this.addVehicle(this.quote)

            } else if (this.quote_activity == 'policy_renewals_edit') {
              this.quote.date_created = ""
              this.quote.updated = ""
              this.quote.status_of_client = "renew"

              this.editVehicle(this.quote)
            }


          }
        })

    }
  }




  createNewQuote(postPayload: any) {
    postPayload.quote_activity = "First"

    this.service.swalAlart.showLoading()
    this.service.createQuote(postPayload)
      .toPromise()
      .then((response: any) => {
        this.service.swalAlart.close()

        this.responseCode = response.responseCode
        this.responseMessage = response.responseMessage

        if (response.responseCode == '000') {
          console.log("responsePayload>>>>>" + response.responseObject)

          localStorage.setItem('motor-quote', JSON.stringify(response.responseObject))
          if (localStorage.getItem("quote_activity") == 'new' || localStorage.getItem("quote_activity") == 'new_add') {
            localStorage.setItem('quote_activity', 'edit_add')
          }


          swal.fire({
            icon: 'success',
            text: 'New vehicle added to quote',
            timer: 5000,
            showConfirmButton: false
          });

          this.router.navigate(["/fleet-motor-quote"])


        } else if (response.responseCode == '400') {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: this.responseMessage,
            icon: 'error',
          });
          this.populateQuoteValues()
        }

        else {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: this.responseMessage,
            icon: 'error',
          });
          this.populateQuoteValues()
        }

      }).catch(error => {
        //console.log('Error',error)
        this.processing = false;
        this.service.swalAlart.close();
        this.service.swalAlart.fire({
          title: 'Server Error',
          text: this.responseMessage,
          icon: 'error',
        });
        this.populateQuoteValues()
      })
  }

  addVehicle(postPayload: any) {
    this.quote.quote_activity = "Add"
    postPayload.quote_objects[this.objecth_position].objecth_id = this.objecth_id
    const addedVehicleObject = this.quote.quote_objects.find((element: any) => element.objecth_id === this.objecth_id);
    postPayload.quote_objects = []
    postPayload.quote_objects.push(addedVehicleObject)
    postPayload.product_description = null

    this.service.swalAlart.showLoading()
    this.service.addVehicle(postPayload)
      .toPromise()
      .then((response: any) => {
        this.service.swalAlart.close()

        this.responseCode = response.responseCode
        this.responseMessage = response.responseMessage

        if (response.responseCode == '000') {
          // console.log("responsePayload>>>>>" + JSON.stringify(response.responseObject))
          // localStorage.setItem('motor-quote', JSON.stringify(response.responseObject))

          if (localStorage.getItem("quote_activity") == 'new' || localStorage.getItem("quote_activity") == 'new_add') {
            localStorage.setItem('quote_activity', 'edit_add')

          } else if (localStorage.getItem("quote_activity") == 'policy_renewals_edit_add') {
            localStorage.setItem('quote_activity', 'policy_renewals_edit_add')
          }

          swal.fire({
            icon: 'success',
            text: 'New vehicle added to quote',
            timer: 5000,
            showConfirmButton: false
          });

          this.router.navigate(["/fleet-motor-quote"])

        } else {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: this.responseMessage,
            icon: 'error',
          });
          this.populateQuoteValues()
        }

      }).catch(error => {
        //console.log('Error',error)
        this.processing = false;
        this.service.swalAlart.close();
        this.service.swalAlart.fire({
          title: 'Server Error',
          text: this.responseMessage,
          icon: 'error',
        });
        this.populateQuoteValues()
      })
  }

  editVehicle(postPayload: any) {
    console.log("objecth_position>>>>>>>>" + this.objecth_position)

    postPayload.quote_activity = "Edit"
    postPayload.quote_objects[this.objecth_position].objecth_id = this.objecth_id
    const editedVehicleObject = this.quote.quote_objects.find((element: any) => element.objecth_id === this.objecth_id);
    console.log("editedVehicleObject>>>>>>>>" + JSON.stringify(editedVehicleObject))

    postPayload.quote_objects = []
    postPayload.quote_objects.push(editedVehicleObject)
    postPayload.product_description = null


    this.service.swalAlart.showLoading()
    this.service.editVehicle(postPayload)
      .toPromise()
      .then((response: any) => {
        this.service.swalAlart.close()

        this.responseCode = response.responseCode
        this.responseMessage = response.responseMessage

        if (response.responseCode == '000') {

          if (localStorage.getItem("quote_activity") == 'policy_renewals_edit') {
            localStorage.setItem('quote_activity', 'policy_renewals_edit')
          }

          console.log("quote_activity>>>>>>>>" + localStorage.getItem("quote_activity"))

          swal.fire({
            icon: 'success',
            text: 'Vehicle details updated',
            timer: 5000,
            showConfirmButton: false
          });

          this.router.navigate(["/fleet-motor-quote"])


        } else {
          this.service.swalAlart.fire({
            title: 'Failed',
            text: this.responseMessage,
            icon: 'error',
          });
          this.populateQuoteValues()
        }

      }).catch(error => {
        //console.log('Error',error)
        this.processing = false;
        this.service.swalAlart.close();
        this.service.swalAlart.fire({
          title: 'Server Error',
          text: this.responseMessage,
          icon: 'error',
        });
        this.populateQuoteValues()
      })



  }



  finalizeQuote = (policy_no) => {
    // console.log('gen_comments>>>>> ' +  this.quote.gen_comments)

    //finalizing the quote creation
    this.payload = {
      gen_comments: this.quote.gen_comments,
      prepared_by: this.payload.prepared_by,
      policy_sequence: policy_no,
      platform: "Webapp",
      module: this.appuser.role,
      status_of_client: this.renewal ? 'renew' : "new",
      vehicle_registration: this.quote.vehicle_registration,
      make: this.selectedMake.VEHICLE_MAKE_DESC,
      model: this.selectedModel.VEHICLE_MODEL_DESC,
      chasis_number: this.quote.chasis_number,
      year_of_manufacture: this.quote.year_of_manufacture.toString(),
      body_type_code: this.quote.body_type_code,
      seats_number: this.quote.seats.toString(),
      currency_code: this.quote.currency_code,
      cubic_capacity: this.quote.cubic_capacity.toString(),
      fleet: "",
      exchange_rate: parseFloat(this.quote.exchange_rate).toFixed(2).toString(),
      duration_type: "",
      sum_insured: this.quote.sum_insured.toString(),
      excess_type_code: (this.quote.excess_buy_back ? 'EIB' : 'ENA'),
      tppd_limit: this.quote.tppd_value ? this.quote.tppd_value : "0",
      legacy_policy_number: this.quote.legacy_policy_number,
      color: this.selectedColor.DISPLAY_SHORT_NAME,
      color_code: parseInt(this.selectedColor.TABLED_CODE),
      customer_first_name: this.quote.customer_first_name,
      customer_last_name: this.quote.customer_last_name,
      customer_other_names: "",
      customer_email: this.quote.customer_email ? this.quote.customer_email : '',
      customer_phone: this.quote.customer_phone,
      customer_date_of_birth: moment(this.quote.date_of_birth).format('DD/MM/YYYY'),
      customer_branch_code: this.appuser.agency,
      customer_type_code: this.quote.customer_type_code,
      additional_pa_amt: this.quote.pa_value ? this.quote.pa_value : "0",
      product: this.quote.product,
      risk: this.quote.schedule_code,
      fleet_number: "",
      age_added: this.quote.vehicle_age_loading ? '1' : '0',
      cc_added: this.quote.cc_loading ? '1' : '0',
      intermediary_number: "AGT-MAXk-0000022",
      intermediary_nic_registration_number: "NIC/MC/020",
      intermediary_first_name: this.appuser.first_name ? this.appuser.first_name : '',
      intermediary_last_name: this.appuser.last_name ? this.appuser.last_name : '',
      intermediary_other_names: "",
      intermediary_phone: this.appuser.phone,
      intermediary_email: this.appuser.email,
      intermediary_type_code: "04",
      intermediary_branch_code: this.appuser.agency,
      ncd_discount_year: this.quote.ncd_value ? this.quote.ncd_value : "0",
      inception_date: moment(this.quote.inception_date).format('DD/MM/YYYY'),
      expiry_date: moment(this.quote.expiry_date).format('DD/MM/YYYY'),
      make_code: parseInt(this.selectedMake.VEHICLE_MAKE_CODE),
      model_code: parseInt(this.selectedModel.VEHICLE_MODEL_CODE),
      mileage: this.quote.mileage,
      policy_agency: this.appuser.agency || '502',
      inputted_discount: this.quote.inputted_discount,
      inputted_loading: this.quote.inputted_loading
    }


    if (this.appuser.role == 'agent') {
      this.payload = {
        ...this.payload,
        agent_code: this.appuser.code.toString(),
        client_code: this.quote.client_code.toString(),
        broker_user_code: '',
      }
    }
    else if (this.appuser.role == 'client') {
      this.payload = {
        ...this.payload,
        agent_code: '',
        client_code: this.appuser.code.toString(),
        broker_user_code: '',
      }
    } else if (this.appuser.role == 'broker-user') {
      this.payload = {
        ...this.payload,
        agent_code: this.appuser.broker_user_main_broker_code.toString(),
        client_code: this.quote.client_code.toString(),
        broker_user_code: this.appuser.code.toString(),
      }
    }

    //pass lagacy values for renewals
    if (this.renewal)
      this.payload = {
        ...this.payload,
        legacy_policy_sequence: this.quote.legacy_policy_sequence,
        legacy_total_premium: this.quote.legacy_total_premium,
        legacy_tppd_limit: this.quote.legacy_tppd_limit,
        legacy_excess_type_code: this.quote.legacy_excess_type_code,
        legacy_cc_added: this.quote.legacy_cc_added,
        legacy_age_added: this.quote.legacy_age_added,
        legacy_additional_pa_amt: this.quote.legacy_additional_pa_amt
      }
    //end lagacy values for renewals
    if (this.quote.days_label.toLowerCase() == 'months' || this.quote.days_label.toLowerCase() == 'month(s)')
      this.payload = {
        ...this.payload,
        months: this.quote.quote_days
      }
    else
      this.payload = {
        ...this.payload,
        days: this.quote.quote_days
      }


    let selectedBody: any = this.bodyData.filter(r => r.TABLED_CODE == this.quote.body_type_code);

    if (selectedBody)
      this.payload.body_type_description = selectedBody[0].TABLED_DESIGNATION

    if (this.quote.duration_type == 'annual')
      this.payload.duration_type = 'annual';
    else if (this.quote.duration_type == 'shortrate')
      this.payload.duration_type = 'shortrate';
    else
      this.payload.duration_type = 'prorata';

    this.service.swalAlart.showLoading();
    this.service.submitMotoQuote(this.payload)
      .toPromise()
      .then((response: any) => {
        if (response['responseCode'] && response['responseCode'] == 'E54')
          this.service.swalAlart.fire({
            title: 'Error',
            text: 'Sorry, this policy is not due for renewal',
            icon: 'error',
          });
        else if (response.responseCode == 'E56')
          this.service.swalAlart.fire({
            title: 'Error',
            text: "Sorry, we couldn't find any branch associated with this account. Kindly contact your branch manager.",
            icon: 'error',
          });
        else if (response.responseCode == 'E57')
          this.service.swalAlart.fire({
            title: 'Error',
            text: "Sorry, you cannot renew policy at this branch,kindly contact your branch office",
            icon: 'error',
          });
        else {
          this.quote.total_premium = response.responseConvertedValue ? response.responseConvertedValue.toString().replace(",", "").trim() : '0';
          this.quote.foreign_currency = response.responseCurrencyValue;
          this.quote.foreign_amount = response.responseData.toString().replace(",", "").trim();
          this.confirmQuote();
          this.service.swalAlart.close();
          this.processing = false;
        }

      }).
      catch(error => {
        //console.log('submit error',error)
        this.processing = false;
        this.service.swalAlart.close()
        this.service.swalAlart.fire({
          title: 'Error',
          text: 'Server Error,retry later',
          icon: 'error',
        });
      })
  }


  dobFilter(date: Date) {
    return moment(moment().format('YYYY-MM-DD')).diff(moment(date).format('YYYY-MM-DD'), 'years') >= 18;
  };

  //trigger the snackbar
  alertSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  //autocomplete search customer
  onFilterCustomer() {
    this.searchCustomerCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredCustomers = [];
          this.isCustomerLoading = true;
        }),
        switchMap(value => {
          if (value && value.length) {

            if (this.appuser.role == "agent") {
              this.filterClientPayload = {
                ...this.filterClientPayload,
                agent_code: this.appuser.code,
                search_parameter: value
              }

            } else if (this.appuser.role == "broker-user") {
              this.filterClientPayload = {
                ...this.filterClientPayload,
                broker_user_code: this.appuser.code,
                search_parameter: value
              }
            }

            console.log("filterClientPayload>>>>>>" + this.filterClientPayload)
            return this.service.searchCustomer(this.filterClientPayload)
              .pipe(
                finalize(() => {
                  this.isCustomerLoading = false
                }),
              )
          } else {
            this.isCustomerLoading = false
            return new Observable<[]>();
          }


        }
        )
      )
      .subscribe((response: CustomerResponse) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {
          response.profile.map(r => {

            r.CLIENT_DATE_OF_BIRTH = this.service.strDateToDBFormat(r.CLIENT_DATE_OF_BIRTH);

            let name_parts = [];
            if (r.CLIENT_FIRST_NAME)
              name_parts.push(r.CLIENT_FIRST_NAME);

            if (r.CLIENT_FAMILY_NAME)
              name_parts.push(r.CLIENT_FAMILY_NAME);

            if (r.CLIENT_HOME_MOBILE)
              name_parts.push(r.CLIENT_HOME_MOBILE);

            //if(r.CLIENT_CODE)
            //name_parts.push(`(${r.CLIENT_CODE})`);

            r.DISPLAY_NAME = name_parts.join(',');

            /*  let temp=name_parts[0];
             name_parts[0]=name_parts[name_parts.length-1];
             name_parts[name_parts.length-1]=temp;

             r.DISPLAY_SHORT_NAME=name_parts.slice(0,name_parts.length-1).join(','); */
            r.DISPLAY_SHORT_NAME = r.DISPLAY_NAME;

            r.isNewCustomer = false;


            this.filteredCustomers.push(r);
          })

        }
      });
  }

  //autocomplete search make
  onLoadMake() {
    this.filteredMakes = [];
    this.loadedMakes = [];

    this.service.searchMake(this.service.currentUser().role == 'agent' ? this.service.currentUser().code : '', '', this.service.currentUser().role == 'agent' ? '' : this.service.currentUser().code)
      .toPromise()
      .then((response: MakeResponse) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {
          response.responseData.map((response: Make) => {
            let make: Make = { ...response, DISPLAY_SHORT_NAME: `${response.VEHICLE_MAKE_DESC}` };
            this.loadedMakes.push(make);
            this.filteredMakes.push(make);
          })
        }
      });

  }

  onFilterMake() {
    this.searchMakeCtrl.valueChanges
      .pipe(
        debounceTime(0),
        tap(() => {
          this.filteredMakes = [];
          this.isMakeLoading = true;
        }),
        switchMap(value => {
          if (value.length) {
            return Observable.create(observer => {
              let payload = this.loadedMakes.filter((response: Make) => response.DISPLAY_SHORT_NAME.trim().toLowerCase().includes(value.trim().toLowerCase()) == true)
              observer.next(payload)
            })
              .pipe(
                finalize(() => {
                  this.isMakeLoading = false
                }),
              )
          }
          else {
            this.isMakeLoading = false
            return new Observable<[]>();
          }
        }
        )
      )
      .subscribe((models: any) => {
        if (models)
          models.map((response: Make) => {

            this.filteredMakes.push({ ...response, DISPLAY_SHORT_NAME: `${response.VEHICLE_MAKE_DESC}` });
          })
      })
  }

  //load models
  onLoadModel() {
    this.filteredModels = [];
    this.loadedModels = [];
    this.service.searchModel(this.service.currentUser().role == 'agent' ? this.service.currentUser().code : '', this.selectedMake.VEHICLE_MAKE_CODE, '', this.service.currentUser().role == 'agent' ? '' : this.service.currentUser().code)
      .toPromise()
      .then((response: any) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {
          response.responseData.map((model: Model) => {
            //console.log('loaded models',model)
            model.DISPLAY_SHORT_NAME = model.VEHICLE_MODEL_DESC;
            this.loadedModels.push(model);
            this.filteredModels.push({ ...model, DISPLAY_SHORT_NAME: `${model.VEHICLE_MODEL_DESC}` });

          });
        }
      })
  }
  //autocomplete search model
  onFilterModel() {
    this.searchModelCtrl.valueChanges
      .pipe(
        debounceTime(0),
        tap(() => {
          this.filteredModels = [];
          this.isModelLoading = true;
        }),
        switchMap((value: string) => {
          if (value) {

            return Observable.create(observer => {
              let payload = this.loadedModels.filter((response: Model) => response.DISPLAY_SHORT_NAME.trim().toLowerCase().includes(value.trim().toLowerCase()) == true)
              observer.next(payload)
            })
              .pipe(
                finalize(() => {
                  this.isModelLoading = false
                }),
              )
          }
          else {
            this.isModelLoading = false;
            this.filteredModels = this.filteredModels;
            return new Observable<[]>();
          }
        }
        )
      ).subscribe((models: any) => {
        if (models)
          models.map((response: Model) => {

            this.filteredModels.push({ ...response, DISPLAY_SHORT_NAME: `${response.VEHICLE_MODEL_DESC}` });
          })
      })
  }
  //autocomplete search color
  onFilterColor() {
    this.searchColorCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredColors = [];
          this.isColorLoading = true;
        }),
        switchMap(value => {
          if (value.length)
            return this.service.searchColor(value)
              .pipe(
                finalize(() => {
                  this.isColorLoading = false
                }),
              )
          else {
            this.isColorLoading = false
            return new Observable<[]>();
          }
        }
        )
      )
      .subscribe((response: colorResponse) => {
        if (response.hasOwnProperty('responseCode') && response.responseCode == '000') {

          response.responseData.map((response: Color) => {
            this.filteredColors.push({ ...response, DISPLAY_SHORT_NAME: `${response.TABLED_DESIGNATION}` })
          })
        }
      });
  }

  //selected selected customer
  onSelectCustomer(customer: CustomerProfile) {
    this.errors = { ...this.errors, client_code: false };

    this.selectedCustomer = customer;
    this.quote.client_code = this.selectedCustomer.CLIENT_CODE
    this.quote.customer_first_name = this.selectedCustomer.CLIENT_FIRST_NAME ? this.selectedCustomer.CLIENT_FIRST_NAME : this.selectedCustomer.CLIENT_FAMILY_NAME
    this.quote.customer_last_name = this.selectedCustomer.CLIENT_FAMILY_NAME
    this.quote.customer_phone = this.selectedCustomer.CLIENT_HOME_MOBILE
    this.quote.customer_email = (this.selectedCustomer.CLIENT_HOME_EMAIL ? this.selectedCustomer.CLIENT_HOME_EMAIL : '')
    this.quote.customer_date_of_birth = this.selectedCustomer.CLIENT_DATE_OF_BIRTH
    this.quote.customer_type_code = this.selectedCustomer.CLIENT_TYPE
  }
  //selected selected customer
  onSelectMake(make: Make) {
    //console.log('make selected',make)
    this.errors = { ...this.errors, make: false };

    this.selectedMake = make;
    this.quote.quote_objects[this.objecth_position].make_code = this.selectedMake.VEHICLE_MAKE_CODE
    this.quote.quote_objects[this.objecth_position].make = this.selectedMake.VEHICLE_MAKE_DESC

    //reset model
    this.quote.quote_objects[this.objecth_position].model_code = ""
    this.quote.quote_objects[this.objecth_position].quote_model = ""

    this.onLoadModel()
  }

  //selected selected customer
  onSelectModel(model: Model) {
    //console.log('model selected',model)

    this.errors = { ...this.errors, model: false };

    this.selectedModel = model;
    this.quote.quote_objects[this.objecth_position].model_code = this.selectedModel.VEHICLE_MODEL_CODE
    this.quote.quote_objects[this.objecth_position].quote_model = this.selectedModel.VEHICLE_MODEL_DESC
  }
  //selected selected color
  onSelectColor(color: Color) {
    this.errors = { ...this.errors, color: false };
    this.selectedColor = color;
    this.quote.quote_objects[this.objecth_position].color_code = this.selectedColor.TABLED_CODE
    this.quote.quote_objects[this.objecth_position].color = this.selectedColor.TABLED_DESIGNATION
  }

  //customer change event
  onCustomerChange(event) {
    if (!event.target.value) {
      this.selectedCustomer = {
        CLIENT_CODE: "",
        CLIENT_FAMILY_NAME: "",
        CLIENT_HOME_MOBILE: "",
        CLIENT_FIRST_NAME: "",
        CLIENT_HOME_EMAIL: "",
        CLIENT_DATE_OF_BIRTH: "",
        DISPLAY_NAME: "",
        DISPLAY_SHORT_NAME: "",
        isNewCustomer: false
      }

      this.quote.customer_first_name = this.selectedCustomer.CLIENT_FIRST_NAME
      this.quote.customer_last_name = this.selectedCustomer.CLIENT_FAMILY_NAME
      this.quote.customer_phone = this.selectedCustomer.CLIENT_HOME_MOBILE
      this.quote.customer_email = (this.selectedCustomer.CLIENT_HOME_EMAIL ? this.selectedCustomer.CLIENT_HOME_EMAIL : '')
      this.quote.customer_date_of_birth = this.selectedCustomer.CLIENT_DATE_OF_BIRTH

    }
  }

  //make change event
  onMakeChange(event) {
    if (!event.target.value) {
      this.selectedMake = {
        VEHICLE_MAKE_CODE: "",
        VEHICLE_MAKE_DESC: "",
        DISPLAY_SHORT_NAME: ""
      }
      this.quote.quote_objects[this.objecth_position].make_code = this.selectedMake.VEHICLE_MAKE_CODE
      this.quote.quote_objects[this.objecth_position].make = this.selectedMake.VEHICLE_MAKE_DESC

      //reset model
      this.quote.quote_objects[this.objecth_position].model_code = ""
      this.quote.quote_objects[this.objecth_position].quote_model = ""
    }
    else
      this.filteredModels = [];
  }
  //make change event
  onModelChange(event) {
    if (!event.target.value) {
      this.selectedModel = {
        VEHICLE_MODEL_CODE: "",
        VEHICLE_MODEL_MAKE_CODE: "",
        VEHICLE_MODEL_DESC: "",
        VEHICLE_MODEL_SECOND_DESC: "",
        DISPLAY_SHORT_NAME: ""
      }
      this.quote.quote_objects[this.objecth_position].model_code = this.selectedModel.VEHICLE_MODEL_CODE
      this.quote.quote_objects[this.objecth_position].model = this.selectedModel.VEHICLE_MODEL_DESC
    }
    else
      this.filteredModels = [];
  }
  onModelClick(event) {
    if (!event.target.value)
      this.filteredModels = this.loadedModels;
  }
  //color change event
  onColorChange(event) {
    if (!event.target.value) {
      this.selectedColor = {
        TABLED_NAME: "",
        TABLED_CODE: "",
        TABLED_DESIGNATION: "",
        DISPLAY_SHORT_NAME: ""
      }
      this.quote.color_code = this.selectedColor.TABLED_CODE
      this.quote.color = this.selectedColor.TABLED_DESIGNATION
    }
  }


}


